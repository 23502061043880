/**
 * CHANGE PIN related contexts
 */

import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CHANGE_PIN_STATUSES } from '../utils/constants';

export const ChangePinContext = React.createContext({
  newPin: '',
  setNewPin: () => {},
  printReceipt: '',
  setPrintReceipt: () => {},
  changePinStatus: '',
  setChangePinStatus: () => {},
  resetChangePinState: () => {},
});

ChangePinContext.displayName = 'ChangePin';

export const useCardPinChangeContext = () => {
  return useContext(ChangePinContext);
}; // to be called in components

export const ProvideChangePinContext = ({ children }) => {
  const [newPin, setNewPin] = useState();
  const [printReceipt, setPrintReceipt] = useState();
  const [changePinStatus, setChangePinStatus] = useState(CHANGE_PIN_STATUSES.INITIAL);

  const resetChangePinState = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setNewPin();
      setPrintReceipt();
      setChangePinStatus(CHANGE_PIN_STATUSES.INITIAL);
    });
  };

  useEffect(() => {
    console.log({ newPin, changePinStatus, printReceipt });
  }, [newPin, changePinStatus, printReceipt]);

  return (
    <ChangePinContext.Provider
      value={{
        newPin,
        setNewPin,
        printReceipt,
        setPrintReceipt,
        changePinStatus,
        setChangePinStatus,
        resetChangePinState,
      }}
    >
      {children}
    </ChangePinContext.Provider>
  );
};
