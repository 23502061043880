import { useCallback, useState, useContext, useMemo } from 'react';
import ReactDOM from 'react-dom';
import defaultTo from 'lodash/defaultTo';
import _get from 'lodash/get';
import upperCase from 'lodash/upperCase';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import keys from 'lodash/keys';
import size from 'lodash/size';
import concat from 'lodash/concat';
import { filter, get } from "lodash";
import { useHistory } from 'react-router';
import { useCustomSnackbar } from '../components/Snackbar';
import { atmTokenName, GET, POST, Storage } from './http';
import usePrevious from '../utils/hooksUtils/usePrevious';
// eslint-disable-next-line import/no-cycle
import {
  dispatchCustomEventToSimulator,
  simulatorEvents,
} from '../utils/simulatorUtils';
import {
  operationTypeModeMap,
  getErrorMessage,
  BARCODE_STATUSES,
  WITHDRAW_LAST_STATUS,
  OPERATION_TYPES,
  PIN_PAD_READER_TYPE,
  FATAL_ERRORS,
  XCurrencyMapping
} from '../utils/constants';
import { TransactionContext, useApiContext } from '../contexts';
import { getDepositsTotal } from '../utils/depositUtil';
import { sleep } from '../utils/extras';
import { useErrorHandler } from './errorHandler';
import { CANCEL_STATUSES } from '../contexts/api';
import { ENDPOINTS } from './endpoints';
import { cardDoneRoutes } from '../containers/cardDone/cardDoneRoutes';
import { useDepositContext } from '../contexts/depositContexts';
import { useBarcodeContext } from '../contexts/barcodeContexts';
import { fatalErrorMessageUrls, WITHDRAW, withdrawErrorMessageUrls } from '../containers/ErrorMessage/errorMessageCollection';
import { useCardContext } from '../contexts/cardContexts';
import { setJwtTokenIfExists } from './apiUtils';
import { useWithdrawContext } from '../contexts/withdrawContexts';
import { messageUrls } from '../containers/message/messageCollection';
// eslint-disable-next-line import/no-cycle
import { money } from '../utils/formatters';
import { useSensorsContext } from '../contexts/sensorsContext';

const setJwtToken = async (token) => {
  console.log(`JWT ${token}`);
  await Storage.setItem(atmTokenName, `JWT ${token}`);
};

const useApi = () => {
  const { setTransaction, operationType } = useContext(TransactionContext);
  const { setMachineError, setReceiptPaperStatus, setReceiptPrinterStatus } = useApiContext();
  const [loading, setLoading] = useState(false);
  // useful for special cases like machine is counting deposited amount
  const [disableLeaveButton, setDisableLeaveButton] = useState(false);
  const history = useHistory();
  const {
    barcodeStatus,
    setBarcodeStatus,
    barcode,
    setBarcode,
    setBarcodeValidationStatus,
    setBarcodeValidationType,
  } = useBarcodeContext();
  const { showWarning, closeSnackbar } = useCustomSnackbar();
  const {
    isInputAllowed,
    setIsInputAllowed,
    setCancelStatus,
    setPinPadInput,
    setShouldSubmit,
    setShouldCancel,
    setForexRateDisplay,
  } = useApiContext();
  const {
    setDepositStatus,
    setDeposits,
    setOtherDeposits,
    setDepositReversible,
    setCanAddMore,
    setSuspectedCounterfeitBills,
    setIsCardTransaction,
  } = useDepositContext();
  const { resetSensorsStateAfterRetry } = useSensorsContext();
  const {
    lastWithdrawalStatus,
    isDispensing,
    setIsDispensing,
    setLastWithdrawalStatus,
    amount: withdrawAmount,
    setAmount: setWithdrawAmount,
  } = useWithdrawContext();
  const { setCardErrorMessage, setCardErrorCode } = useCardContext();
  const { onError, onErrorClearJwtToken } = useErrorHandler({ setLoading });
  const [poCreated, setPoCreated] = useState(false);

  const operationMode = useMemo(() => {
    return _get(operationTypeModeMap, operationType) || 'card';
  }, [operationType]);

  const beginScan = useCallback(
    async (clearToken = true) => {
      setLoading(true);

      if (clearToken) {
        Storage.removeItem(atmTokenName);
      }

      const response = await POST(ENDPOINTS.BEGIN_SCAN, null, onError);
      const atmToken = response.token;

      setJwtToken(atmToken);

      setLoading(false);
      return true;
    },
    [setLoading, onError],
  );

  const getScanResult = () => {
    return new Promise((resolve) => {
      const SCAN_SUCCESS_STATUS = 1;
      const pollResult = async () => {
        const response = await GET(ENDPOINTS.SCAN_RESULT, onError);
        if (response.status === SCAN_SUCCESS_STATUS) {
          setLoading(false);
          resolve();
        } else {
          setTimeout(pollResult, 500);
        }
      };
      setLoading(true);
      pollResult();
    });
  };

  const getAccountBalance = useCallback(async () => {
    setLoading(true);
    const response = await GET(ENDPOINTS.ACCOUNT_BALANCE, null, onError);
    setLoading(false);
    return _get(response, 'balanceList', []);
  }, [setLoading, onError]);

  /**
   * End account balance check with print receipt function
   *
   * @param {Boolean} printReceipt - print receipt or not?
   */
  const endAccountBalance = async (printReceipt) => {
    setLoading(true);
    const response = await POST(
      ENDPOINTS.ACCOUNT_BALANCE_END,
      { print_receipt: printReceipt },
      onError,
    );
    console.log('endAccountBalance:response', response);
    setLoading(false);
  };

  const validateTxId = useCallback(
    async (txId) => {
      Storage.removeItem(txId);
      const response = await POST(ENDPOINTS.VALIDATE_TX_ID, { txid_numeric: txId }, onError);
      await setJwtToken(_get(response, 'token', ''));

      const pollResult = async () => {
        const validationStatus = await GET(ENDPOINTS.VALIDATION_RESULT, (err, res) => {
          const errMsg = getErrorMessage(_get(res, 'status'), res.statusText);
          closeSnackbar();
          showWarning(errMsg);
          setLoading(false);
        });
        console.log('validationStatus', validationStatus);
        const token = _get(validationStatus, 'token');
        const data = _get(validationStatus, 'txTransaction.data');

        if (token) {
          /**
           * Update token if present in the response
           * this new token has tx_id
           */
          await setJwtToken(token);
        }

        if (_get(validationStatus, 'txValidation.status') === 1 && !!data) {
          setTransaction({ ...JSON.parse(data), id: txId });
          Storage.setItem(txId, data);
          setLoading(false);
        } else {
          /**
           * Using setTimeout with async will not work
           * use sleep as a work around for timeout, pollResult will be called after 1000ms
           */
          await sleep(1000);
          await pollResult();
        }
      };
      setLoading(true);
      await pollResult();
    },
    [setLoading, onError, showWarning, closeSnackbar],
  );

  const resetDepositState = () => {
    // method to reset deposit context to initial state
    // reset deposit state to initial
    setDepositStatus('');
    setDeposits([]);
    setDepositReversible(true);
    setCanAddMore(true);
    setIsCardTransaction(false);
    setForexRateDisplay("");
    console.log("resetDepositState done!")
  };

  const beginDeposit = useCallback(async (cardTransaction = false) => {
    resetDepositState();
    setLoading(true);
    const url = cardTransaction ? ENDPOINTS.CARD_BEGIN_DEPOSIT : ENDPOINTS.BEGIN_DEPOSIT;
    const response = await POST(url, null, onError);
    await setJwtTokenIfExists(response);
    dispatchCustomEventToSimulator(simulatorEvents['start-deposit']);
    setLoading(false);
  }, [setLoading, onError]);

  const getDepositStatus = useCallback(
    (payAmount, resolveIfHasAmount = false, autoBeginDeposit = true) => {
      return new Promise((resolve) => {
        let simulatorDoorOpened = false;

        const pollResult = async () => {
          const response = await GET(ENDPOINTS.GET_DEPOSIT_STATUS);
          const status = response.depositStatus;
          const deposits = _get(response, 'deposits', []);
          const totalDeposited = getDepositsTotal(deposits);

          if (status === -1) {
            try {
              await POST(ENDPOINTS.CANCEL);
            } catch (error) {
              console.log(error);
            }
            history.push(cardDoneRoutes.invalid_bill);
          } else if (status === 2) {
            /**
             * Machine is counting deposited amount
             * continue polling
             */
            setDisableLeaveButton(true);
            setTimeout(() => pollResult(), 1000);
          } else if (autoBeginDeposit && status === 0 && totalDeposited < payAmount) {
            /**
             * if the shutter is closed and deposited amount is not enough
             * call begin deposit and restart get deposit status
             */
            await POST(ENDPOINTS.BEGIN_DEPOSIT, null, onError);
            dispatchCustomEventToSimulator(simulatorEvents['start-deposit']);
            setTimeout(() => {
              pollResult();
            }, 1000);
          } else if (status === 0 && size(deposits) < 1) {
            // poll result if no deposited amount yet
            setTimeout(() => pollResult(), 1000);
          } else if (status !== 0 && !(resolveIfHasAmount && totalDeposited >= payAmount)) {
            // simulator
            if (!simulatorDoorOpened) {
              dispatchCustomEventToSimulator(simulatorEvents['open-door']);
              dispatchCustomEventToSimulator(simulatorEvents['start-timeout']);
              simulatorDoorOpened = true;
            }

            // door still open, poll status
            setTimeout(() => {
              pollResult();
            }, 1000);
          } else {
            setLoading(false);
            setDisableLeaveButton(false);
            dispatchCustomEventToSimulator(simulatorEvents['close-door']);
            resolve(response);
          }
        };
        setLoading(true);
        pollResult();
      });
    },
    [setLoading, beginDeposit, setDisableLeaveButton],
  );

  const getDepositStatusOnce = async (cardTransaction = false) => {
    const url = cardTransaction ? ENDPOINTS.CARD_GET_DEPOSIT_STATUS : ENDPOINTS.GET_DEPOSIT_STATUS;
    const response = await GET(url);
    console.log('getDepositStatusOnce', {
      ...response, depositTotal: getDepositsTotal(_get(response, 'deposits', []))
    });

    /**
     * Combine deposits and deposits other since they are the same deposits with just different currency
     * Currency for deposits will be automatically set with defaultCurrency from constant
     */
    const depositsOther = _get(response, 'depositsOther', [])
    const depositsCombined = concat(_get(response, 'deposits', []), depositsOther);

    ReactDOM.unstable_batchedUpdates(() => {
      setDepositStatus(_get(response, 'depositStatus'));
      setDeposits(depositsCombined);
      // filter x currencies
      setSuspectedCounterfeitBills(
        filter(depositsOther, (deposit) =>
          includes(keys(XCurrencyMapping), get(deposit, 'currency')),
        ),
      );
      setOtherDeposits(depositsOther);
      setDepositReversible(_get(response, 'depositReversible', true));
    });

    return response;
  };

  const getDepositShutterStatus = useCallback(async () => {
    return new Promise((resolve) => {
      /**
       * Resolve if shutter is closed
       */
      const pollResult = async () => {
        const response = await GET(ENDPOINTS.GET_DEPOSIT_STATUS);
        const status = response.depositStatus;

        console.log('ShutterStatus', status);

        if (status === 0) {
          setLoading(false);
          resolve(true);
        } else {
          setTimeout(pollResult, 1000);
        }
      };

      setLoading(true);
      pollResult();
    });
  }, [setLoading]);

  const depositConfirm = useCallback(
    async (printReceipt, cardTransaction = false) => {
      setLoading(true);
      const url = cardTransaction ? ENDPOINTS.CARD_CONFIRM_DEPOSIT : ENDPOINTS.CONFIRM_DEPOSIT;
      await POST(url, { print_receipt: printReceipt }, onError);
      setLoading(false);
    },
    [setLoading, onError],
  );

  /**
   * Do not throw error, use callbackOnError instead when error occurs to run necessary cleaning
   */
  const beginWithdrawal = async (payload, callbackOnError = noop) => {
    setWithdrawAmount(0); // reset withdraw amount
    return new Promise((resolve) => {
      const callBegin = async () => {
        const response = await POST(ENDPOINTS.BEGIN_WITHDRAWAL, payload, onError);
        console.log('beginWithdrawal', response);
        const errorCode = _get(response, 'errorCode', '');

        if (includes(keys(WITHDRAW.MESSAGES), String(errorCode))) {
          callbackOnError(); // run necessary clean-up before redirecting
          history.push(_get(withdrawErrorMessageUrls, errorCode));
        } else {
          resolve(true);
        }
      };

      callBegin();
    });
  };


  /**
   * Call Continue withdrawal
   *
   * @returns {Promise} empty if there's no error, and reject with reason code for error
   */
  const continueWithdrawal = async ({ amount }) => {
    const response = await POST(ENDPOINTS.CONTINUE_WITHDRAWAL, { amount });
    setWithdrawAmount(amount);
    console.log('continueWithdrawal', response);
    const errorCode = _get(response, 'errorCode', '');

    return new Promise((resolve, reject) => {
      if (errorCode) {
        setMachineError(errorCode);
      }

      if (includes(FATAL_ERRORS, errorCode)) {
        // Only fatal errors can throw error at this point
        const redirectErrorUrl = _get(fatalErrorMessageUrls, errorCode);

        if (redirectErrorUrl) {
          history.push(redirectErrorUrl);
        } else {
          console.log(`Redirect URL for error ${errorCode} was not found!`);
        }

        reject(errorCode);
      } else {
        resolve(true);
      }
    });
  };

  const withdrawConfirm = useCallback(async (cardTransaction = false) => {
    setLoading(true);
    const url = cardTransaction ? ENDPOINTS.CARD_WITHDRAW_CONFIRM : ENDPOINTS.WITHDRAW_CONFIRM;
    await POST(url, null, onError);
    setLoading(false);
  }, [setLoading, onError]);

  const endWithdrawal = async () => {
    setWithdrawAmount(0); // reset withdraw amount
    const response = await POST(ENDPOINTS.CARD_END_WITHDRAWAL);
    console.log('endWithdrawal', response);
  };

  const getWithdrawStatus = async (isCardTransaction = false) => {
    return new Promise((resolve) => {
      const pollResult = async () => {
        // todo: update for final syntax
        const url = isCardTransaction ? ENDPOINTS.CARD_WITHDRAW_STATUS : ENDPOINTS.WITHDRAW_STATUS
        const response = await GET(url, onError);
        const isDispensingResponse = _get(response, "isDispensing");
        const lastWithdrawalStatusResponse = upperCase(_get(response, "lastWithdrawalStatus", ""))

        console.log('getWithdrawStatus', response);

        setIsDispensing(isDispensingResponse);
        setLastWithdrawalStatus(lastWithdrawalStatusResponse);

        // only resolve if not anymore dispensing and bill is not retracted
        const isDone = !isDispensingResponse && lastWithdrawalStatusResponse !== WITHDRAW_LAST_STATUS.RETRACTED;
        const isRetracted = !isDispensingResponse && lastWithdrawalStatusResponse === WITHDRAW_LAST_STATUS.RETRACTED;
        if (isDone) {
          resolve(true);
        } else if (isRetracted) {
          setTimeout(() => {
            dispatchCustomEventToSimulator(simulatorEvents.receipt, {
              detail: {
                operationType: OPERATION_TYPES.WITHDRAW,
                amount: `${money(withdrawAmount)} CZK`,
                amountRaw: withdrawAmount,
                message: "Cash retracted. Please contact support",
              },
            });

          }, 1000);

          setTimeout(() => {
            setWithdrawAmount(0); // reset withdraw amount
          }, 3000);

          history.push(messageUrls.cashRetracted);
        } else {
          setTimeout(pollResult, 1000);
        }
      };

      pollResult();
    });
  };

  const canWithdraw = useCallback(
    async (payload, cardTransaction = false) => {
      setLoading(true);
      const url = cardTransaction ? ENDPOINTS.CARD_CAN_WITHDRAW : ENDPOINTS.CAN_WITHDRAW;
      const response = await POST(url, payload, onError);
      setLoading(false);
      /**
       * Response format:
       * [boolean, boolean, boolean, boolean]
       * - if the machine has >= count [n] bills
       */
      return _get(response, 'canWithdraw', []);
    },
    [setLoading],
  );

  const getSupportedCurrencies = useCallback(
    async (countryCode) => {
      setLoading(true);
      const response = await POST(
        ENDPOINTS.GET_SUPPORTED_CURRENCIES,
        { country: countryCode },
        onError,
      );
      setLoading(false);
      return response;
    },
    [setLoading],
  );

  const forexCalculate = async (payload) => {
    /**
     * @return {Object} - {"currency": String, "amount": Float, "crossRate": Float,
     *    "rateCalculationID": Int}
     */
    setLoading(true);
    const response = await POST(ENDPOINTS.FOREX_CALCULATE, payload, onError);
    setLoading(false);
    return defaultTo(response, {});
  };

  const confirm = async () => {
    const response = await POST(ENDPOINTS.API_CONFIRM, null, onError);
    console.log('confirm response', response);

    resetDepositState();
  };

  const createPo = async payload => {
    setLoading(true);
    const response = await POST(ENDPOINTS.CREATE_PO, payload, onError);

    await setJwtTokenIfExists(response);
    console.log('createPo response', response);
    setLoading(false);
  }

  const getCreatePoResultOnce = async () => {
    setLoading(true);
    const createPoResult = await GET(ENDPOINTS.CREATE_PO_RESULT, (err, res) => {
      const errMsg = getErrorMessage(_get(res, 'status'), res.statusText);
      closeSnackbar();
      showWarning(errMsg);
    });

    console.log('createPoResult', createPoResult);
    await setJwtTokenIfExists(createPoResult);

    if (_get(createPoResult, 'txValidation.status') === 1) {
      setLoading(false);
      setPoCreated(true);
    }
    return createPoResult;
  };

  const getSensorsStatus = async () => {
    return new Promise((resolve) => {
      const pollResult = async () => {
        const response = await GET(ENDPOINTS.SENSORS_STATUS, (err, res) => {
          const handled = onErrorClearJwtToken(err, res)
          if (handled) {
            // if properly handled, poll again
            setTimeout(pollResult, 1000);
          }
        });
        const proximityStatus = _get(response, "proximityStatus")
        if (_get(response, "receiptPrinterStatus")) {
          setReceiptPaperStatus(_get(response, "receiptPrinterStatus.paperStatus"))
          setReceiptPrinterStatus(_get(response, "receiptPrinterStatus.printerStatus"))
        }

        console.log('proximityStatus', proximityStatus);

        if (proximityStatus === 0) {
          setLoading(false);
          resolve(true);
        } else {
          setTimeout(pollResult, 5000);
        }
      };
      setLoading(true);
      pollResult();
    });
  };

  const getSensorsStatusOnce = async () => {
    const response = await GET(ENDPOINTS.SENSORS_STATUS, onErrorClearJwtToken);
    await setJwtTokenIfExists(response);
    if (_get(response, "receiptPrinterStatus")) {
      setReceiptPaperStatus(_get(response, "receiptPrinterStatus.paperStatus"))
      setReceiptPrinterStatus(_get(response, "receiptPrinterStatus.printerStatus"))
    }
    return response;
  };

  const skipFacialRecognition = useCallback(
    async () => {
      const response = await POST(ENDPOINTS.SKIP_DETECTION, null, onErrorClearJwtToken);
      return response;
    },
    [onErrorClearJwtToken],
  )

  const retryFacialRecognition = useCallback(
    async () => {
      setLoading(true);
      return new Promise(async resolve => {
        const response = await POST(ENDPOINTS.RESET_FACIAL_DETECTION, null, onErrorClearJwtToken);
        console.log(`retryFacialRecognition:response`, response);
        resetSensorsStateAfterRetry();

        // add 3 seconds delay before resolving
        setTimeout(() => {
          setLoading(false); // to simulate loading time
          resolve(response);
        }, 1000);
      })
    },
    [],
  )

  const beginBarcodeRead = async (type = "TXID") => {
    const response = await POST(ENDPOINTS.BARCODE_BEGIN_READ, { type });
    console.log('beginBarcodeRead:response', response);
    return response;
  };

  const getBarcodeReadStatus = async () => {
    const response = await GET(ENDPOINTS.BARCODE_READ_STATUS);
    setBarcodeValidationStatus(_get(response, 'validation.status', ''));
    setBarcodeValidationType(_get(response, 'validation.type', ''));
    setBarcodeStatus(_get(response, 'status', ''));
    setBarcode(_get(response, 'barcode', ''));
    console.log('getBarcodeReadStatus:response', response);
    return response;
  };

  const endBarcodeRead = async () => {
    // there's no endpoint call for end, reset context state here
    setBarcodeStatus(BARCODE_STATUSES.INITIAL);
    setBarcode('');
  };

  const reInit = async () => {
    const response = await POST(ENDPOINTS.RE_INIT);
    console.log('reInit:response', response);
    return response;
  }

  const resetInputContext = () => {
    return new Promise(resolve => {
      setPinPadInput("");
      setShouldSubmit(false);
      setShouldCancel(false);
      resolve();
    })
  }

  const beginInput = useCallback(
    async (pinPadReaderType = PIN_PAD_READER_TYPE.AMOUNT) => {
      await resetInputContext();
      const response = await POST(ENDPOINTS.BEGIN_INPUT, ({ pinPadReaderType }), onError);
      setIsInputAllowed(true);
      console.log('beginInput', response);
    },
    [setIsInputAllowed],
  );

  const inputKey = useCallback(
    async (key) => {
      if (isInputAllowed) {
        await POST(ENDPOINTS.SEND_INPUT, { key });
      }
    },
    [isInputAllowed],
  );

  const getInput = () => GET(ENDPOINTS.GET_INPUT, null, onError);

  const endInput = useCallback(
    async () => {
      await resetInputContext();
      const response = await POST(ENDPOINTS.END_INPUT, null, onError);
      console.log('endInput', response);
      setIsInputAllowed(false);
      setPinPadInput(""); // reset input
    },
    [setIsInputAllowed],
  );

  const getCancelStatus = async () => {
    const response = await GET(ENDPOINTS.GET_CANCEL_STATUS);
    const cancellationStatus = _get(response, 'cancellationStatus');
    if (cancellationStatus) {
      setCancelStatus(cancellationStatus);
    }
    console.log('getCancelStatus:response', response);
    return cancellationStatus;
  };

  const cancel = async () => {
    let status = {};
    /**
     * Wait for cancel before calling other methods
     */
    try {
      setCancelStatus(CANCEL_STATUSES.CANCELLING);
      const response = await POST(ENDPOINTS.CANCEL);
      const cancellationStatus = await getCancelStatus(); // get first cancel status
      console.log('cancel:response', response);
      status = { cancellationStatus };
    } catch (error) {
      console.log('cancel:error', error);
    } finally {
      resetDepositState();
      endBarcodeRead();
      window.numberOfAjaxCallPending = 0; // reset pending ajax call count
      // regardless of response, set status to cancelled, use timeout to prevent race condition
      // setTimeout(setCancelStatus, 100, CANCEL_STATUSES.CANCELLED);

      // clear cancel status after timeout
      // setTimeout(setCancelStatus, 3000, CANCEL_STATUSES.INITIAL);
    }

    return status;
  };

  return {
    loading,
    prevLoading: usePrevious(loading), // previous value for loading
    getAccountBalance,
    endAccountBalance,
    beginScan,
    cancel,
    getCancelStatus,
    getScanResult,
    validateTxId,
    beginDeposit,
    getDepositStatus,
    getDepositStatusOnce,
    resetDepositState,
    getDepositShutterStatus,
    depositConfirm,
    withdrawConfirm,
    getWithdrawStatus,
    canWithdraw,
    beginWithdrawal,
    continueWithdrawal,
    endWithdrawal,
    getSupportedCurrencies,
    forexCalculate,
    confirm,
    createPo,
    poCreated, // boolean if po is created
    getCreatePoResultOnce,
    getSensorsStatus,
    getSensorsStatusOnce,
    reInit,
    beginBarcodeRead,
    getBarcodeReadStatus,
    barcode,
    barcodeStatus,
    endBarcodeRead,
    beginInput,
    inputKey,
    getInput,
    endInput,
    disableLeaveButton,
    isInputAllowed,
    setIsInputAllowed,
    operationMode,
    resetInputContext,
    lastWithdrawalStatus,
    isDispensing,
    skipFacialRecognition,
    retryFacialRecognition,
  };
};

export default useApi;
