import axios from 'axios';
import each from 'lodash/each';
import get from 'lodash/get';
import includes from 'lodash/includes';
import removeObjectEmptyValues from '@dynameyes/js-utils/removeObjectEmptyValues';
import { errorReportHandler, successReportHandler } from './slackNotification';
import { httpInterceptors, interceptorsCleanup } from './httpInterceptors';
import { ENDPOINTS } from './endpoints';
import { LANGUAGE_STORAGE_KEY } from '../utils/constants';

export const atmTokenName = 'atm-token';
export const STORAGE_CARD_READ_ID = 'atm-card-read-id';

export const Storage =
  process.env.NODE_ENV === 'development' ? window.localStorage : window.localStorage;

export const cancellableEndpoints = [ENDPOINTS.CARD_READ_STATUS];
export const cancelStack = {};
export const recordCancelStack = (cancelTokenSource) => {
  const id = Date.now();
  cancelStack[id] = cancelTokenSource;
  return id;
};

export const cancelAllPending = () => {
  return new Promise(resolve => {
    console.log('cancelAllPending:cancelStack', cancelStack)
    each(cancelStack, (source) => {
      source.cancel("cancel-pending");
    })
    resolve(true);
  })
}

export const POST = (url, data, onError) => {
  /**
   * TODO:
   * Implement cancel method for pending request
   * typically it's useful if user decided to end transaction while there's pending api call
   */
  const { CancelToken } = axios;
  const source = CancelToken.source();
  // cancelStack.push({
  //   url,
  //   ...source,
  // })
  // simulateClickEvent();
  let cancelId = null;
  if (includes(cancellableEndpoints, url)) {
    cancelId = recordCancelStack(source);
  }
  return new Promise((resolve) => {
    const interceptors = httpInterceptors(url);
    axios({
      url,
      method: 'POST',
      headers: removeObjectEmptyValues({
        Authorization: Storage.getItem(atmTokenName),
        'Accept-Language': Storage.getItem(LANGUAGE_STORAGE_KEY),
      }),
      data,
      cancelToken: source.token,
    })
      .then((response) => {
        successReportHandler(response);
        resolve(response.data);
      })
      .catch((err) => {
        errorReportHandler(err);

        console.log('An error occurred in http.js:POST() :', err);
        if (onError) onError(err, get(err, 'response', {}));
      })
      .finally(() => {
        interceptorsCleanup(...interceptors);
        delete cancelStack[cancelId]; // delete finished request
      });
  });
};
export const GET = (url, onError) => {
  /**
  * TODO:
  * Implement cancel method for pending request
  * typically it's useful if user decided to end transaction while there's pending api call
  */
  const { CancelToken } = axios;
  const source = CancelToken.source();
  // cancelStack.push({
  //   url,
  //   ...source,
  // })
  // simulateClickEvent();
  let cancelId = null;
  if (includes(cancellableEndpoints, url)) {
    cancelId = recordCancelStack(source);
  }
  return new Promise((resolve) => {
    const interceptors = httpInterceptors(url);
    axios({
      url,
      method: 'GET',
      headers: removeObjectEmptyValues({
        Authorization: Storage.getItem(atmTokenName),
        'Accept-Language': Storage.getItem(LANGUAGE_STORAGE_KEY),
      }),
      cancelToken: source.token,
    })
      .then((response) => {
        successReportHandler(response);
        resolve(response.data);
      })
      .catch((err) => {
        errorReportHandler(err);

        console.log('An error occurred in http.js:GET() :', err);
        if (onError) onError(err, get(err, 'response', {}));
      })
      .finally(() => {
        interceptorsCleanup(...interceptors);
        delete cancelStack[cancelId]; // delete finished request
      });
  });
};

export const RAW_GET = (url, onError) => {
  return new Promise((resolve) => {
    axios({
      url,
      method: 'GET',
    })
      .then((response) => resolve(response.data))
      .catch((err) => {
        console.log('An error occurred in http.js:RAW_GET() :', err);
        if (onError) onError(err, get(err, 'response', {}));
      });
  });
};

export default {
  POST,
  GET,
  atmTokenName,
  Storage,
};
