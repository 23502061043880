import Loader from '../components/LoadingComponent';

export const AsyncLanding = Loader({
  loader: () => import('../containers/landing'),
});

export const AsyncStart = Loader({
  loader: () => import('../containers/Start'),
});

export const AsyncOffline = Loader({
  loader: () => import('../containers/Offline'),
});

export const AsyncScan = Loader({
  loader: () => import('../containers/scan'),
});

export const AsyncBalanceInquiry = Loader({
  loader: () => import('../containers/balanceInquiry'),
});

export const AsyncMessage = Loader({
  loader: () => import('../containers/message'),
});

export const AsyncCardEjected = Loader({
  loader: () => import('../containers/cardEjected'),
});

export const AsyncCardDone = Loader({
  loader: () => import('../containers/cardDone'),
});

export const AsyncSendMoney = Loader({
  loader: () => import('../containers/sendMoney'),
});

export const AsyncTransferSummary = Loader({
  loader: () => import('../containers/transferSummary'),
});

export const AsyncTransferSuccess = Loader({
  loader: () => import('../containers/transferSuccess'),
});

export const AsyncDeposit = Loader({
  loader: () => import('../containers/deposit'),
});

export const AsyncDepositToCard = Loader({
  loader: () => import('../containers/depositToCard'),
});

export const AsyncDepositErrorInvalidBills = Loader({
  loader: () => import('../containers/DepositErrorContainer/InvalidBills'),
});

export const AsyncLanguage = Loader({
  loader: () => import('../containers/language'),
});

export const AsyncReceiveMoney = Loader({
  loader: () => import('../containers/receiveMoney'),
});

export const AsyncScanQR = Loader({
  loader: () => import('../containers/scanQR'),
});

export const AsyncWithdraw = Loader({
  loader: () => import('../containers/withdraw'),
});

export const AsyncTransferNew = Loader({
  loader: () => import('../containers/transferNew'),
});

export const AsyncTestPage = Loader({
  loader: () => import('../containers/testPage'),
});

export const AsyncSamplePage = Loader({
  loader: () => import('../containers/samplePage'),
});

export const AsyncSimulator = Loader({
  loader: () => import('../containers/simulator'),
});

export const AsyncWithdrawFromCard = Loader({
  loader: () => import('../containers/withdrawFromCard'),
});

export const AsyncCardTransaction = Loader({
  loader: () => import('../containers/cardTransaction'),
});

export const AsyncSuccessThankYou = Loader({
  loader: () => import('../containers/successThankYou'),
});

export const AsyncCancelBuffer = Loader({
  loader: () => import('../containers/cancelBuffer'),
});

export const AsyncCardReadError = Loader({
  loader: () => import('../containers/cardReadError'),
});

export const AsyncChangePin = Loader({
  loader: () => import('../containers/changePin'),
});

export const AsyncErrorMessage = Loader({
  loader: () => import('../containers/ErrorMessage'),
});

export const AsyncHomeBuffer = Loader({
  loader: () => import('../containers/homeBuffer'),
});

export const AsyncNoReceipt = Loader({
  loader: () => import('../containers/NoReceipt'),
});

export const AsyncSuspectedCounterfeitBills = Loader({
  loader: () => import('../containers/SuspectedCounterfeitBills'),
});

export const AsyncMaintenance = Loader({
  loader: () => import('../containers/Maintenance'),
});
