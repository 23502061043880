import React from 'react';

const EllipsisLoader = ({ svgStyles, width, height, color = '#f79326' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      margin: 'auto',
      display: 'block',
      shapeRendering: 'geometricPrecision',
      ...svgStyles,
    }}
    width={width || '100px'}
    height={height || '50px'}
    viewBox="0 25 100 50"
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="84" cy="50" r="3.49559" fill={color}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="0.78125s"
        calcMode="spline"
        keyTimes="0;1"
        values="10;0"
        keySplines="0 0.5 0.5 1"
        begin="0s"
      />
      <animate
        attributeName="fill"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="discrete"
        keyTimes="0;0.25;0.5;0.75;1"
        values={`${color};`.repeat(5)}
        begin="0s"
      />
    </circle>
    <circle cx="16" cy="50" r="0" fill={color}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0s"
      />
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0s"
      />
    </circle>
    <circle cx="16" cy="50" r="6.50441" fill={color}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.78125s"
      />
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.78125s"
      />
    </circle>
    <circle cx="38.115" cy="50" r="10" fill={color}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-1.5625s"
      />
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-1.5625s"
      />
    </circle>
    <circle cx="72.115" cy="50" r="10" fill={color}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-2.34375s"
      />
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="3.125s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-2.34375s"
      />
    </circle>
  </svg>
);

export default EllipsisLoader;
