import { map, get, each, some, startsWith, includes } from 'lodash';
import { roundUp } from '../containers/transferSummary/transferSummaryUtil';
import { money } from './formatters';
import { defaultCurrency, XCurrencyMapping, XCurrencyKeys } from './constants';

export const getDepositsTotal = (deposits = []) => {
  let totalDeposited = 0;
  each(deposits, (deposit) => {
    /**
     * Only add to total deposit if in default currency or no currency (means default currency)
     */
    const currency = get(deposit, 'currency', defaultCurrency); // set defaultCurrency to deposits without currency
    if (currency === defaultCurrency) {
      totalDeposited += Number(deposit.amount);
    }
  });
  return totalDeposited;
};

/**
 * Check if other deposits has valid minimum amount
 *
 * @typedef Deposit
 * @property {Number} amount
 * @property {String} currency
 *
 * @param {Deposit[]} otherDeposits
 * @param {Number} minAmount - minimum amount for deposit to be valid
 * @returns {Boolean}
 */
export const isOtherDepositValid = (otherDeposits, minAmount = 0) =>
  some(otherDeposits, (deposit) => Number(get(deposit, 'amount', 0)) > minAmount);

/**
 * Deposits object from backend can return multiple amounts deposits for the same currency
 * To support multi currency deposit, combine same currency deposits
 */
export const combineDepositsByCurrency = (deposits, combineXCurrencies = false) => {
  const depositByCurrency = {};
  each(deposits, (deposit) => {
    let currency = get(deposit, 'currency', defaultCurrency); // set defaultCurrency to deposits without currency

    if (combineXCurrencies) {
      currency = get(XCurrencyMapping, currency, currency);
    }
    /**
     * Check if amount for currency already set, else set to 0
     * Check if amount for currency from api deposits is set (expected to have a value), else set to 0
     */
    depositByCurrency[currency] =
      Number(get(depositByCurrency, currency, 0)) + Number(get(deposit, 'amount', 0));
  });
  console.log(`depositByCurrency`, depositByCurrency);
  return map(depositByCurrency, (amount, currency) => ({ amount, currency }));
};

export const getDepositDetails = (deposits, transaction) => {
  const deposited = getDepositsTotal(deposits);
  const toDeposit = roundUp(transaction.rawPayAmount, 100);
  const left = deposited > toDeposit ? 0 : toDeposit - deposited;
  const topup = deposited > toDeposit ? deposited - toDeposit : 0; // change

  return {
    deposited,
    left,
    topup,
  };
};

export const getFormattedDepositDetails = (deposits, transaction) => {
  const { deposited, left, topup } = getDepositDetails(deposits, transaction);
  const fromCurrency = get(transaction, 'fromCurrency', '');

  return {
    deposit: `${money(deposited)} ${fromCurrency}`,
    topupDisplay: `${money(topup)} ${fromCurrency}`,
    topupAmount: topup,
    deposited: `${money(deposited, 0)} ${fromCurrency}`,
    left: `${money(left, 0)} ${fromCurrency}`,
    leftRaw: left,
    topup: `${money(topup, 0)} ${fromCurrency}`,
  };
};

/**
 * Check if deposit list includes CONFISCATED currency
 *
 * @param {Array} depositList
 */
export const depositHasConfiscated = (depositList = []) => {
  return some(depositList, (deposit) => startsWith(get(deposit, 'currency'), 'CONFISCATED'));
};

/**
 * Check if deposit list includes X Currency
 *
 * @param {Array} depositList
 */
export const depositHasXCurrency = (depositList = []) => {
  return some(depositList, (deposit) => includes(XCurrencyKeys, get(deposit, 'currency')));
};
