import { useEffect, useCallback, useState, useRef } from 'react';

// placeholder method for promise function
const noopPromise = () => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), 1000);
    });
};

// wait function for the next iteration
const wait = (ms = 1000) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Interval like polling but will wait for response from previous call
 *
 * @param {Boolean} startPolling - to check if to start polling
 * @param {Promise} promiseFn - should be a promised base method, handling for error also should be internal
 * @param {Number} pollTimeMs
 * @returns
 */
export const usePoll = (startPolling, promiseFn = noopPromise, pollTimeMs = 1000) => {
    const isMountedRef = useRef(true);
    const refStartPolling = useRef(startPolling);
    const [response, setResponse] = useState();

    const pollNow = useCallback(
        async () => {
            const data = await promiseFn();
            setResponse(data);

            /**
             * Delay every after calling, equivalent to interval time
             */
            await wait(pollTimeMs);

            /**
             * Recursive call pollNow() only if mounted
             *
             * Since this callback is called Recursively
             * Add checking if reference isMountedRef and refStartPolling is still true
             * Reference is used here because state value will not work
             * because this method is called in Recursive pattern
             */
            if (isMountedRef.current && refStartPolling.current) {
                pollNow();
            }
        },
        [pollTimeMs, promiseFn],
    )

    useEffect(() => {
        /**
         * Add reference to start polling
         */
        refStartPolling.current = startPolling;

        /**
         * Check if start polling
         * Only have to be called once, and pollNow will handle recursive call
         */
        if (startPolling) {
            pollNow();
        }
    }, [startPolling, pollNow])

    /**
     * On unmount set is mount ref to false to stop polling
     */
    useEffect(() => {
        return () => {
            isMountedRef.current = false
        }
    }, [])

    return { response, isMounted: isMountedRef.current, startPolling, pollTimeMs }
}
