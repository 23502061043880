import _split from 'lodash/split';
import _pick from 'lodash/pick';
import commonEn from './en/common.json';
import commonCs from './cs/common.json';
import commonUk from './uk/common.json';

export const SUPPORTED_LANGUAGES = _split(
  process.env.REACT_APP_SUPPORTED_LANGUAGES || 'en,cs',
  ',',
);

const languages = {
  cs: 'Čeština',
  en: 'English',
  uk: 'Український',
};

export const supportedLanguages = _pick(languages, SUPPORTED_LANGUAGES);

/**
 * These are the translation resources to be use
 */
export const resources = {
  en: {
    common: commonEn, // 'common' is our custom namespace
  },
  cs: {
    common: commonCs, // 'common' is our custom namespace
  },
  uk: {
    common: commonUk, // 'common' is our custom namespace
  },
};
