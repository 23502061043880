const apiDomain = `${process.env.REACT_APP_API_DOMAIN}/api`;

export const ENDPOINTS = {
  ACCOUNT_BALANCE: `${apiDomain}/account-balance`,
  ACCOUNT_BALANCE_END: `${apiDomain}/card/balance/end`,
  BEGIN_SCAN: `${apiDomain}/cash/begin-scan`,
  SCAN_RESULT: `${apiDomain}/cash/scan-result`,
  VALIDATE_TX_ID: `${apiDomain}/cash/validate-txid`,
  VALIDATION_RESULT: `${apiDomain}/cash/validation-result`,
  GET_DEPOSIT_STATUS: `${apiDomain}/cash/get-deposit-status`,
  SIMULATOR_DEPOSIT_BILLS: `${apiDomain}/deposit-bills`,
  BEGIN_DEPOSIT: `${apiDomain}/cash/begin-deposit`,
  CONFIRM_DEPOSIT: `${apiDomain}/cash/confirm-deposit`,
  WITHDRAW_CONFIRM: `${apiDomain}/cash/confirm-withdrawal`,
  WITHDRAW_STATUS: `${apiDomain}/cash/withdrawal-status`,
  DONE_FLOW: `${apiDomain}/done-flow`,
  GET_SUPPORTED_CURRENCIES: `${apiDomain}/supported-currencies`,
  FOREX_CALCULATE: `${apiDomain}/forex-calculate`,
  API_CONFIRM: `${apiDomain}/cash/confirm-deposit`,
  SENSORS_START: `${apiDomain}/begin-sensors`,
  SENSORS_STATUS: `${apiDomain}/sensor-status`,
  SENSORS_END: `${apiDomain}/end-sensors`,
  SKIP_DETECTION: `${apiDomain}/skip-detection`,
  BARCODE_BEGIN_READ: `${apiDomain}/begin-barcode-read`,
  BARCODE_READ_STATUS: `${apiDomain}/get-barcode-read-status`,

  CARD_BEGIN_DEPOSIT: `${apiDomain}/card/begin-deposit`,
  CARD_CONFIRM_DEPOSIT: `${apiDomain}/card/confirm-deposit`,
  CARD_GET_DEPOSIT_STATUS: `${apiDomain}/card/get-deposit-status`,
  CARD_WITHDRAW_CONFIRM: `${apiDomain}/card/confirm-withdrawal`,
  CARD_WITHDRAW_STATUS: `${apiDomain}/card/withdrawal-status`,
  CARD_BEGIN_WITHDRAWAL: `${apiDomain}/card/begin-withdrawal`,
  CARD_CONTINUE_WITHDRAWAL: `${apiDomain}/card/continue-withdrawal`,
  CARD_CAN_WITHDRAW: `${apiDomain}/card/can-withdraw`,
  CARD_END_WITHDRAWAL: `${apiDomain}/card/end-withdrawal`,

  CREATE_PO: `${apiDomain}/cash/create-po`,
  CREATE_PO_RESULT: `${apiDomain}/cash/create-po-result`,

  BEGIN_INPUT: `${apiDomain}/begin-input`,
  SEND_INPUT: `${apiDomain}/input-key`,
  GET_INPUT: `${apiDomain}/get-input`,
  END_INPUT: `${apiDomain}/end-input`,
  CARD_INSERT: `${apiDomain}/sim/insert-card`,
  CARD_READ_START: `${apiDomain}/begin-card-read`,
  CARD_READ_STATUS: `${apiDomain}/card-read-status`,
  CARD_READ_END: `${apiDomain}/end-card-read`,
  CARD_VALIDATE_PIN: `${apiDomain}/validate-pin`,
  CARD_VALIDATE_PIN_RESULT: `${apiDomain}/validate-pin-result`,
  CHANGE_PIN: `${apiDomain}/change-pin`,
  GET_CHANGE_PIN_STATUS: `${apiDomain}/get-change-pin-status`,
  CARD_EJECT: `${apiDomain}/eject-card`,
  CARD_TAKE: `${apiDomain}/sim/take-card`,
  BEGIN_WITHDRAWAL: `${apiDomain}/cash/begin-withdrawal`,
  CONTINUE_WITHDRAWAL: `${apiDomain}/cash/continue-withdrawal`,
  CAN_WITHDRAW: `${apiDomain}/cash/can-withdraw`,
  GET_WITHDRAWAL_STATUS: `${apiDomain}/cash/withdrawal-status`,
  END_WITHDRAWAL: `${apiDomain}/cash/end-withdrawal`,
  CANCEL: `${apiDomain}/cancel`,
  GET_CANCEL_STATUS: `${apiDomain}/get-cancellation-status`,

  GET_CAMERA_IMAGE: `${apiDomain}/camera/image`,

  MAINTENANCE_STATE: `${apiDomain}/maintenance/state`,
  MAINTENANCE_BEGIN_CARD_RETRIEVAL: `${apiDomain}/maintenance/cards`,
  MAINTENANCE_BEGIN_EXCHANGE_CASSETTES: `${apiDomain}/maintenance/cassettes/exchange`,
  MAINTENANCE_UPDATE_CASSETTES_COUNT: `${apiDomain}/maintenance/cassettes/counts`,
  MAINTENANCE_BEGIN_REPLACE_RECEIPT_ROLL: `${apiDomain}/maintenance/paper`,
  MAINTENANCE_CONTINUE_EMPTY_RECEIPT_ROLL: `${apiDomain}/maintenance/paper/confirm_none`,
  MAINTENANCE_CANCEL: `${apiDomain}/maintenance/cancel`,

  SIMULATOR_TAKE_CASH: `${apiDomain}/sim/take-cash`,

  SIM_ACTIVATE_SENSORS: `${apiDomain}/sim/activate-sensors`,
  SIM_QR_CODE: `${apiDomain}/sim/scan-qr-code`,
  RESET_FACIAL_DETECTION: `${apiDomain}/reset-detection`,
  GET_MACHINE_INFO: `${apiDomain}/machine-info`,
  RE_INIT: `${apiDomain}/reinit`,
};

/**
 * Collection of endpoints that used for polling
 * This is used for slack notification throttling
 */
export const POLL_ENDPOINTS = [
  ENDPOINTS.GET_DEPOSIT_STATUS,
  ENDPOINTS.BARCODE_READ_STATUS,
  ENDPOINTS.CARD_GET_DEPOSIT_STATUS,
  ENDPOINTS.GET_INPUT,
  ENDPOINTS.GET_CHANGE_PIN_STATUS,
  ENDPOINTS.GET_CANCEL_STATUS,
  ENDPOINTS.CARD_READ_STATUS,
  ENDPOINTS.GET_MACHINE_INFO,
]
