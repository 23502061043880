import { useMemo, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

export const useCustomSnackbar = (autoClose = true) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { location } = useHistory();

  useEffect(() => {
    // auto close is set, close snackbar on page change
    if (autoClose) {
      closeSnackbar();
    }
  }, [location.pathname, autoClose, closeSnackbar]);

  return useMemo(() => {
    const show = (variant) => (message, key = null, action = null) => {
      let options = { action };
      if (key) {
        options = {
          key,
          preventDuplicate: true,
        };
      }
      return enqueueSnackbar(message, { variant, ...options });
    };

    return {
      showMessage: show('info'),
      showInfo: show('info'),
      showWarning: show('warning'),
      showError: show('error'),
      showSuccess: show('success'),
      closeSnackbar,
    };
  }, [enqueueSnackbar, closeSnackbar]);
};
