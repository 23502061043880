import React from 'react';
import ReactDOM from 'react-dom';
import './localize';
import '../node_modules/bootstrap-utilities/bootstrap-utilities.css';
import './index.css';
import './assets/fonts/fonts.css';
import App from './App';
import { logRocketInit } from './logging';
import * as serviceWorker from './serviceWorker';

console.log(
  `%c(VERSION):${process.env.REACT_APP_VERSION}`,
  'background: #222; color: #bada55; font-size: 18px',
);

logRocketInit();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
