import { urlPaths } from '../../router/routes';
import { FATAL_ERRORS } from '../../utils/constants';

const FATAL_ERROR_1006 = FATAL_ERRORS[1006]; // add variable since this is re-used a couple of times
const FATAL_ERROR_1007 = FATAL_ERRORS[1007]; // add variable since this is re-used a couple of times

/**
 * Because some error code are re-used but have different messages, add mapping here
 */
export const errorMessageUrls = {
  cash1004: `${urlPaths.errorMessage}/?type=withdraw&code=1004`,
  cash1005: `${urlPaths.errorMessage}/?type=withdraw&code=1005`,
  cash1101: `${urlPaths.errorMessage}/?type=withdraw&code=1101`,
};

/**
 * cash withdrawal error message urls
 * it's easier to maintain a single file for urls
 */
export const withdrawErrorMessageUrls = {
  1004: errorMessageUrls.cash1004,
  1005: errorMessageUrls.cash1005,
  1101: errorMessageUrls.cash1101,
};

export const fatalErrorMessageUrls = {
  [FATAL_ERROR_1006]: `${urlPaths.errorMessage}/?type=fatal&code=1006`,
  [FATAL_ERROR_1007]: `${urlPaths.errorMessage}/?type=fatal&code=1007`,
};

/**
 * Withdraw related errors
 * map for withdraw error message, next path and button label
 */
export const WITHDRAW = {
  MESSAGES: {
    1004: 'This transfer can not be withdrawn on this ATM because the amounts must be divisible in 200 or 500 bills.',
    1005: 'This transfer can not be withdrawn on this ATM because this machine can only dispense CZK bills',
    1101: 'This transfer has already been fulfilled in the past.',
  },
  NEXT_PATH: {
    1004: urlPaths.homeBuffer,
    1005: urlPaths.homeBuffer,
    1101: urlPaths.homeBuffer,
  },
  BUTTON_LABEL: {
    1004: '',
    1005: '',
    1101: '',
  },
};

export const depositErrorMessageUrls = {
  cardOverloadDeposit: `${urlPaths.errorMessage}/?type=deposit&code=cardOverloadDeposit&cancel_on_unmount=true`,
  cashOverloadDeposit: `${urlPaths.errorMessage}/?type=deposit&code=cashOverloadDeposit&cancel_on_unmount=true`,
};

export const DEPOSIT = {
  TITLE: {
    cardOverloadDeposit: 'Deposit Error',
    cashOverloadDeposit: 'Deposit Error',
  },
  MESSAGES: {
    cardOverloadDeposit: 'Please make sure that all the bills are valid and do not exceed {{maxDepositBills}} pieces',
    cashOverloadDeposit: 'Please make sure that all the bills are valid and do not exceed {{maxDepositBills}} pieces',
  },
  NEXT_PATH: {
    cardOverloadDeposit: `${urlPaths.cardEjected}?check_cancel=true`,
    cashOverloadDeposit: urlPaths.cancelBufferPage,
  },
  BUTTON_LABEL: {
    cardOverloadDeposit: '',
    cashOverloadDeposit: '',
  },
};

/**
 * Fatal error messages
 */
export const FATAL = {
  MESSAGES: {
    [FATAL_ERROR_1006]: 'Internal machine error. Could not dispense cash.',
    [FATAL_ERROR_1007]:
      // eslint-disable-next-line max-len
      'Internal machine error. Could not dispense cash. If the amount is not reversed within 3 banking days, please contact us at {{contact_email}}',
  },
  NEXT_PATH: {
    [FATAL_ERROR_1006]: urlPaths.homeBuffer,
    [FATAL_ERROR_1007]: urlPaths.homeBuffer,
  },
  BUTTON_LABEL: {
    [FATAL_ERROR_1006]: '',
    [FATAL_ERROR_1007]: '',
  },
};

/**
 * QR related error/message
 */
export const qrCodeErrorMessageUrls = {
  notRecognized: `${urlPaths.errorMessage}/?type=qr&code=not_recognized`,
  needsScanMobile: `${urlPaths.errorMessage}/?type=qr&code=needs_scan_mobile`,
};

export const QR = {
  MESSAGES: {
    not_recognized: 'Sorry, the QR code is not recognized.',
    needs_scan_mobile: 'To continue, please scan the QR code with your mobile device.',
  },
  NEXT_PATH: {
    not_recognized: urlPaths.homeBuffer,
    needs_scan_mobile: urlPaths.homeBuffer,
  },
  BUTTON_LABEL: {
    not_recognized: '',
    needs_scan_mobile: '',
  },
};
