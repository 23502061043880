/**
 * BARCODE related contexts
 */

import React, { useContext, useState } from 'react';

export const BarcodeContext = React.createContext({
  barcodeStatus: '',
  setBarcodeStatus: () => {},
  barcode: '',
  setBarcode: () => {},
  barcodeValidationStatus: '',
  setBarcodeValidationStatus: () => {},
  barcodeValidationType: '',
  setBarcodeValidationType: () => {},
});

BarcodeContext.displayName = 'Barcode';

export const useBarcodeContext = () => {
  return useContext(BarcodeContext);
}; // to be called in components

export const useProvideBarcode = () => {
  const [barcodeStatus, setBarcodeStatus] = useState('');
  const [barcode, setBarcode] = useState('');
  const [barcodeValidationStatus, setBarcodeValidationStatus] = useState('');
  const [barcodeValidationType, setBarcodeValidationType] = useState('');

  return {
    barcodeStatus,
    setBarcodeStatus,
    barcode,
    setBarcode,
    barcodeValidationStatus,
    setBarcodeValidationStatus,
    barcodeValidationType,
    setBarcodeValidationType,
  };
};

export const ProvideBarcode = ({ children }) => {
  const barcode = useProvideBarcode();
  return <BarcodeContext.Provider value={barcode}>{children}</BarcodeContext.Provider>;
};
