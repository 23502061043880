import i18n from 'i18next';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import _size from 'lodash/size';
import { get, keys, memoize, includes } from 'lodash';
import { initReactI18next, useTranslation } from 'react-i18next';
import { useMemo, useCallback, useContext } from 'react';
import { resources, supportedLanguages, SUPPORTED_LANGUAGES } from './translations';
import { LanguageContext } from './contexts';
import { dispatchCustomEventToSimulator, simulatorEvents } from './utils/simulatorUtils';
import { missingTranslationReportHandler } from './api/slackNotification';
import { CURRENT_LANGUAGE_STORE, LANGUAGE_STORAGE_KEY } from './utils/constants';

export const useLocalize = (namespace = 'common') => {
  const { t, i18n: locI18n } = useTranslation(namespace);
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const changeLanguage = useCallback(
    (lang) => {
      setCurrentLanguage(lang);
      window.localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
      dispatchCustomEventToSimulator(simulatorEvents.changeLanguage, { detail: { lang } });
      locI18n.changeLanguage(lang);
    },
    [setCurrentLanguage, locI18n],
  );

  /**
   * Get next language in supported language collection
   *
   * i.e. supported language = { en: 'English', cs: 'Čeština' }
   * current: 'en'
   * next: 'cs'
   */
  const nextLanguage = useMemo(() => {
    const currentIndex = _indexOf(SUPPORTED_LANGUAGES, currentLanguage);
    const nextIndex = currentIndex + 1;
    const sizeZeroBased = _size(SUPPORTED_LANGUAGES) - 1;
    if (nextIndex <= sizeZeroBased) {
      const key = _get(SUPPORTED_LANGUAGES, nextIndex);
      return {
        key,
        name: _get(supportedLanguages, key),
      };
    }

    const key = _get(SUPPORTED_LANGUAGES, 0);
    return {
      key,
      name: _get(supportedLanguages, key),
    };
  }, [currentLanguage]);

  /**
   * Toggle current language based on supported languages order
   * 1st toggle will select first index, 2nd will select 2nd index and so on...
   */
  const toggleLanguage = useCallback(() => changeLanguage(nextLanguage.key), [
    nextLanguage,
    changeLanguage,
  ]);

  const currentLanguageName = useMemo(() => _get(supportedLanguages, currentLanguage), [
    currentLanguage,
  ]);

  return {
    gettext: t,
    changeLanguage,
    currentLanguage,
    isCurrentEnglish: currentLanguage === "en",
    currentLanguageName,
    nextLanguage,
    toggleLanguage,
    supportedLanguages,
  };
};

const englishSource = get(resources, "en.common", {});
const memoizedMissingKeyHandler = memoize((missingKey) => {
  /**
   * Check from reference resource if key is really missing
   */
  const isKeyMissingFromResource = !includes(keys(englishSource), missingKey);

  if (isKeyMissingFromResource) {
    missingTranslationReportHandler(missingKey);
  }
});

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    lng: CURRENT_LANGUAGE_STORE,
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    debug: true,
    saveMissing: true,
    missingKeyHandler: (lng, ns, missingKey, fallbackValue) =>
      memoizedMissingKeyHandler(missingKey),
    referenceLng: 'en',
    returnEmptyString: false,
    keySeparator: false,
    nsSeparator: false,
    defaultNS: 'common',
    resources,
  });

export default i18n;
