import React from 'react';
import { SnackbarProvider } from 'notistack';
import { useCustomSnackbarStyles } from './SnackbarStyles';

export * from './useCustomSnackbar';

const autoHideDuration = (process.env.REACT_APP_NOTIFICATION_DURATION || 15) * 1000;

const CustomSnackbarProvider = ({ children }) => {
  const classes = useCustomSnackbarStyles();
  return (
    <SnackbarProvider
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={classes}
      hideIconVariant
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
