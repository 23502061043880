/**
 * WITHDRAW related contexts
 */

import React, { useContext, useState } from 'react';

export const WithdrawContext = React.createContext({
  withdrawStatus: '',
  setWithdrawStatus: () => {},
  lastWithdrawalStatus: '',
  setLastWithdrawalStatus: () => {},
  isDispensing: false,
  setIsDispensing: () => {},
  amount: 0,
  setAmount: () => {},
});

WithdrawContext.displayName = 'Withdraw';

export const useWithdrawContext = () => useContext(WithdrawContext); // to be called in components

export const useProvideWithdraw = () => {
  const [withdrawStatus, setWithdrawStatus] = useState('');
  const [lastWithdrawalStatus, setLastWithdrawalStatus] = useState('');
  const [isDispensing, setIsDispensing] = useState(false);
  const [amount, setAmount] = useState(0);

  return {
    withdrawStatus,
    setWithdrawStatus,
    lastWithdrawalStatus,
    setLastWithdrawalStatus,
    isDispensing,
    setIsDispensing,
    amount,
    setAmount,
  };
};

export const ProvideWithdraw = ({ children }) => {
  const deposit = useProvideWithdraw();
  return <WithdrawContext.Provider value={deposit}>{children}</WithdrawContext.Provider>;
};
