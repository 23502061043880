import { makeStyles } from '@material-ui/core/styles';
import { themeColors } from '../../theme';

const fontSize = 22;
export const useCustomSnackbarStyles = makeStyles({
  root: {
    top: 50,
  },
  variantSuccess: {
    fontSize,
    textAlign: 'center',
  },
  variantError: {
    fontSize,
    textAlign: 'center',
  },
  variantInfo: {
    fontSize,
    textAlign: 'center',
  },
  variantWarning: {
    fontSize,
    textAlign: 'center',
    color: themeColors.black,
    backgroundColor: themeColors.snackbarWarning,
  },
});
