import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useGoogleAnalytics } from './logging';
import theme from './theme';
import RootRoute from './router/RootRoute';
import CustomSnackbarProvider from './components/Snackbar';
import {
  GoogleAnalyticsContext,
  LanguageContext,
  TransactionContext,
  ProvideApi,
  ProvideCard,
  ProvideContextApiWrapper,
} from './contexts';
import { simulatorEvents, dispatchCustomEventToSimulator } from './utils/simulatorUtils';
import { ProvideAds } from './contexts/adsContexts';
import { AttachSimulatorDirector } from './containers/simulator/simulatorDirector';
import { ProvideBarcode } from './contexts/barcodeContexts';
import { CURRENT_LANGUAGE_STORE } from './utils/constants';
import { disableTouchEvents } from './utils/browser';
import { BrowserRouter } from 'react-router-dom';
import AttachTerminalStatusChecking from './components/hooks/AttachTerminalStatusChecking';

const App = () => {
  const [gaInitialized, setGaInitialized] = useState(false);
  const { initGoogleAnalytics } = useGoogleAnalytics();
  const [currentLanguage, setCurrentLanguage] = useState(CURRENT_LANGUAGE_STORE);
  const [transaction, setTransaction] = useState({});
  const [receiptDetails, setReceiptDetails] = useState({});
  const [operationType, setOperationType] = useState();

  useEffect(() => {
    disableTouchEvents();
  }, [])

  useEffect(() => {
    initGoogleAnalytics();
  }, [initGoogleAnalytics]);

  useEffect(() => {
    dispatchCustomEventToSimulator(simulatorEvents.operationType, { detail: operationType });
  }, [operationType]);

  return (
    <BrowserRouter>
      <ProvideApi>
        <TransactionContext.Provider
          value={{
            transaction,
            setTransaction,
            operationType,
            setOperationType,
            receiptDetails,
            setReceiptDetails,
          }}
        >
          <GoogleAnalyticsContext.Provider value={{ gaInitialized, setGaInitialized }}>
            <LanguageContext.Provider
              value={{
                currentLanguage,
                setCurrentLanguage,
              }}
            >
              <ThemeProvider theme={theme}>
                <ProvideCard>
                  <ProvideContextApiWrapper>
                    <ProvideBarcode>
                      <CustomSnackbarProvider>
                        <ProvideAds>
                          <AttachTerminalStatusChecking />
                          <AttachSimulatorDirector />
                          <RootRoute />
                        </ProvideAds>
                      </CustomSnackbarProvider>
                    </ProvideBarcode>
                  </ProvideContextApiWrapper>
                </ProvideCard>
              </ThemeProvider>
            </LanguageContext.Provider>
          </GoogleAnalyticsContext.Provider>
        </TransactionContext.Provider>
      </ProvideApi>
    </BrowserRouter>
  );
};

export default App;
