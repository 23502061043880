import React from 'react';

/**
 * Reusable layout component that will align all children/items in center
 *
 * Note: Requires height (in px)
 */
const CommonLayoutCenter = ({ height = 400, children, ...otherProps }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height }} {...otherProps}>
      {children}
    </div>
  )
}

/**
 * displayName is used as filter for findByType method
 */
CommonLayoutCenter.displayName = 'CommonLayoutCenter';

export default CommonLayoutCenter
