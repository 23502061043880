import { createMuiTheme, colors as _colors } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const screenWidth = 1024;
export const screenHeight = 768;

export const themeColors = {
  ..._colors,
  white: '#ffffff',
  black: '#000000',
  primary: '#303545',
  secondary: '#f79326',
  secondaryText: '#525252',
  topBar: '#3b3b3a',
  amountTitle: '#7b7b7b',
  amountValue: '#303545',
  snackbarWarning: '#fbc992',
  success: '#06a772',
  h3: '#31324a',
  h5: '#332f4d',
  currency: '#233756',
};

export const contrastColors = {
  secondary: '#fff',
};

// separate theme colors since UI different from other screens
export const startThemeColors = {
  topBar: themeColors.topBarColor,
  mainText: themeColors.white,
  subMainText: '#d9d9d9',
  bigBtnTitle: '#263b61',
  bigBtnSubTitle: '#717171',
};

export const themeFontSets = {
  AvenirNext: 'AvenirNext, OpenSans, sans-serif',
  OpenSans: 'OpenSans, sans-serif',
};

export default createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: themeFontSets.OpenSans,
        fontWeight: 600,
      },
      contained: {
        boxShadow: '0 4px 7px 0 rgba(37, 61, 95, 0.1)',

        '&.Mui-disabled': {
          backgroundImage: 'unset',
        },
      },
      containedPrimary: {
        backgroundImage: 'linear-gradient(to bottom, #385784, #303545)',
      },
      containedSecondary: {
        backgroundImage: 'linear-gradient(to bottom, #ffb565, #f79326)',
      },
      startIcon: {
        display: 'block',
        textAlign: 'left',
        width: 32,
        height: 32,
        marginLeft: 'unset',
        marginRight: 'unset',
      },
      endIcon: {
        display: 'block',
        textAlign: 'right',
        width: 32,
        height: 32,
        marginLeft: 'unset',
        marginRight: 'unset',
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: fade(themeColors.primary, 0.25),
        },
      },
    },
  },
  palette: {
    primary: {
      main: themeColors.primary,
    },
    text: {
      primary: themeColors.primary,
    },
    secondary: {
      main: themeColors.secondary,
      contrastText: themeColors.white,
    },
  },
  typography: {
    fontFamily: themeFontSets.OpenSans,
    fontWeightRegular: 100,
    fontWeightBold: 600,

    h1: {
      fontSize: 96,
      fontWeight: 600,
    },
    h2: {
      fontSize: 60,
      fontWeight: 600,
    },
    h3: {
      fontSize: 44,
      fontWeight: 'bold',
      lineHeight: 1.2,
      color: themeColors.h3,
    },
    h4: {
      fontSize: 32,
      fontWeight: 100,
    },
    h5: {
      fontSize: 24,
      fontWeight: 'normal',
      color: themeColors.h5,
    },
    h6: {
      fontSize: 20,
      fontWeight: 100,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,

      '&.MuiTypography-colorTextSecondary': {
        color: themeColors.secondaryText,
      },
    },
  },
});
