import atmMoney from "../../assets/images/icons/atm-money.png"
import cardCapture from "../../assets/images/icons/card-capture.png"
import { urlPaths } from '../../router/routes';
import { MESSAGES } from '../../utils/messages';

/**
 * Because some code are re-used but have different messages, add mapping here
 */

export const messageUrls = {
  cashRetracted: `${urlPaths.message}/?type=withdraw&code=retracted`,
  cardCaptured: `${urlPaths.message}/?type=card&code=captured`,
  cardCapturedFromCancel: `${urlPaths.message}/?type=card_from_cancel&code=captured`,
};

/**
 * cash withdrawal message urls
 * it's easier to maintain a single file for urls
 */

export const withdrawErrorMessageUrls = {
  retracted: messageUrls.cashRetracted,
};

// map for withdraw message, next path and button label
export const WITHDRAW = {
  MESSAGES: {
    retracted: MESSAGES.RETRACTED,
  },
  ICON: {
    retracted: atmMoney,
  },
  NEXT_PATH: {
    retracted: urlPaths.homeBuffer,
  },
  BUTTON_LABEL: {
    retracted: '',
  },
};

export const CARD = {
  MESSAGES: {
    captured: MESSAGES.CARD_CAPTURED,
  },
  ICON: {
    captured: cardCapture,
  },
  NEXT_PATH: {
    captured: urlPaths.homeBuffer,
  },
  BUTTON_LABEL: {
    captured: '',
  },
};

export const CARD_FROM_CANCEL = {
  MESSAGES: {
    captured: MESSAGES.CARD_CAPTURED,
  },
  ICON: {
    captured: cardCapture,
  },
  NEXT_PATH: {
    captured: urlPaths.cancelBufferPage,
  },
  BUTTON_LABEL: {
    captured: '',
  },
};
