import clickBeepSound from '../assets/media/click_beep.mp3';

export const isInsideIFrame = window !== window.parent;

export const playClickBeep = () => {
  try {
    new Audio(clickBeepSound).play();
  } catch (error) {
    console.error("An error occurred while trying to play beep sound", error)
  }
}

export const simulateClickEvent = () => {
  try {
    // dispatch touch event
    window.document.dispatchEvent(new Event('click'));
  } catch (error) {
    console.log('simulateClickEvent:error', error);
  }
}

export const disableTouchEvents = () => {
  function touchHandler(event) {
    /**
      * this event is multi-touch
      */
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  }
  /**
   * This is the start of the touch event
   * Next will be multi-touch events like touchmove
   */
  window.addEventListener("touchstart", touchHandler, false);
}
