import axios from 'axios';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import upperCase from 'lodash/upperCase';
import getCookie from '@dynameyes/js-utils/getCookie';
import { ENDPOINTS, POLL_ENDPOINTS } from './endpoints';

const slackIncomingWebHook = process.env.REACT_APP_SLACK_NOTIFICATION_URL;

// default value is 5 seconds
const SLACK_NOTIFICATION_POLLING_THROTTLE = process.env.REACT_APP_SLACK_NOTIFICATION_POLLING_ENDPOINT_THROTTLE || 5

const storage = window.localStorage;

export const pushNotificationToSlack = ({
  method,
  errorCode,
  errorMessage,
  url: endpoint,
  data = {}
}) => {

  if (endpoint === ENDPOINTS.VALIDATE_TX_ID && errorCode === 404) {
    // this is not an error
    return;
  }

  if (!slackIncomingWebHook) {
    return;
  }

  if (includes(POLL_ENDPOINTS, endpoint)) {
    // fallback to empty "{}"
    const notificationStack = JSON.parse(storage.getItem("notification-stack") || "{}");
    // convert to date from storage, if not existing set to now
    const lastNotificationSent =
      get(notificationStack, endpoint) ? new Date(get(notificationStack, endpoint)) : new Date();
    // convert last sent to seconds
    const secondsFromLastSent = (new Date().getTime() - lastNotificationSent.getTime()) / 1000;

    // throttle notify to slack if within throttle set
    if (secondsFromLastSent && secondsFromLastSent < SLACK_NOTIFICATION_POLLING_THROTTLE) {
      console.warn("pushNotificationToSlack throttled", {
        method,
        errorCode,
        errorMessage,
        endpoint,
        data
      })
      return
    }

    notificationStack[endpoint] = new Date();
    storage.setItem("notification-stack", JSON.stringify(notificationStack))
  }

  axios({
    url: slackIncomingWebHook,
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `An unexpected error has occurred: <${getCookie("logrocket_URL")}|Logrocket Session>`
          }
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": `*${errorCode}:* ${errorMessage}`
            },
            {
              "type": "mrkdwn",
              "text": `*${upperCase(method)}:* ${endpoint}`
            }
          ]
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": `*Body:* \`${JSON.stringify(data)}\``
            }
          ]
        }
      ]
    }
  }).then(response => console.log('pushNotificationToSlack:response', response.data))
}

const KEYS_OK_ON_FIRST_ERROR = [401]; // keys that is okay to throw an error at first try

const createErrorStackKey = axiosErrorObject => get(axiosErrorObject, "url");

const errorStack = {
  collection: {},
  push(err) {
    try {
      this.collection[createErrorStackKey(err)] = err;
    } catch (error) {
      console.log("errorStack:push", error)
    }
  },
  shouldPostError(err) {
    try {
      const key = get(err, "errorCode");

      // key in here can be in code+url combination
      const errorFromStack = get(this.collection, createErrorStackKey(err));

      this.push(err); // push to error stack after getting historical stack (errorFromStack)

      // check if error is allowed
      if (includes(KEYS_OK_ON_FIRST_ERROR, key)) {
        if (isEmpty(errorFromStack)) return false; // if stack is empty, it means first time error

        // if errorFromStack is not empty, it already throw an error
        return true;
      }
    } catch (error) {
      console.log("shouldPostError:error", error)
    }
    return true;
  },
  deleteFromErrors(axiosResponse) {
    if (isEmpty(this.collection)) return; // if error stack is empty, there's nothing to delete
    try {
      delete this.collection[get(axiosResponse, "config.url")];
    } catch (error) {
      console.log('deleteFromErrors:error', error);
    }
  }
};

// call this if there's a successful api call, to clear a particular error from stack
export const successReportHandler = (axiosResponse) => errorStack.deleteFromErrors(axiosResponse);

export const errorReportHandler = (axiosErrorObject) => {
  const method = get(axiosErrorObject, "config.method");
  const errorCode = get(axiosErrorObject, "response.status");
  const errorMessage = defaultTo(
    get(axiosErrorObject, "response.statusText"),
    get(axiosErrorObject, "message"),
  );
  const url = get(axiosErrorObject, "config.url");

  const errorObject = {
    method,
    errorCode,
    errorMessage,
    url,
    timestamp: Date.now()
  }

  const shouldPostError = errorStack.shouldPostError(errorObject);

  if (shouldPostError) {
    pushNotificationToSlack(errorObject);
  }
}

export const missingTranslationReportHandler = missingKey => {
  axios({
    url: slackIncomingWebHook,
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "Missing translation key"
          }
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": `\`${missingKey}\``
            }
          ]
        },
        {
          "type": "context",
          "elements": [
            {
              "type": "plain_text",
              "text": "PayAxe ATM"
            }
          ]
        }
      ]
    }
  }).then(response => console.log('missingTranslationReportHandler:response', response.data))
}
