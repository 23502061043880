import axios from 'axios';
import includes from "lodash/includes";
import { ENDPOINTS } from './endpoints';

window.numberOfAjaxCallPending = 0;

const interceptUrlCollection = [
  ENDPOINTS.VALIDATE_TX_ID,
  ENDPOINTS.CARD_VALIDATE_PIN,
]

export const interceptorsCleanup = (requestInterceptor, responseInterceptor) => {
  axios.interceptors.request.eject(requestInterceptor);
  axios.interceptors.response.eject(responseInterceptor);
}

export const httpInterceptors = (url) => {
  if (!includes(interceptUrlCollection, url)) {
    return [];
  }

  // Add a request interceptor
  const requestInterceptor = axios.interceptors.request.use((config) => {
    window.numberOfAjaxCallPending++;
    // show loader
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Add a response interceptor
  const responseInterceptor = axios.interceptors.response.use((response) => {
    window.numberOfAjaxCallPending--;
    // console.log(`------------  Ajax pending ${response.config.url}`, window.numberOfAjaxCallPending);

    if (window.numberOfAjaxCallPending === 0) {
      // hide loader
      // console.log('response', response)
    }
    return response;
  }, (error) => {
    window.numberOfAjaxCallPending--;
    if (window.numberOfAjaxCallPending === 0) {
      // hide loader
    }
    return Promise.reject(error);
  });

  return [requestInterceptor, responseInterceptor]
}
