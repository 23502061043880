import React, { useEffect } from 'react';
import { useLocalize } from '../../localize';
import { urlPaths } from '../../router/routes';
import useApi from '../../api/api';
import FatalErrorMessageFragment from '../../components/PageFragments/FatalErrorMessageFragment';
import { useApiContext } from '../../contexts';
import { OPERATION_STATUSES } from '../../utils/constants';

let pollSensorStatus = 0;

const Offline = ({ history }) => {
  const { gettext } = useLocalize();
  const { getSensorsStatusOnce } = useApi();
  const { terminalOperationStatus } = useApiContext();

  useEffect(() => {
    pollSensorStatus = setInterval(() => {
      getSensorsStatusOnce().then(({ proximityStatus }) => {
        if (proximityStatus === 1) {
          history.push(urlPaths.home);
        }
      });
    }, 10000);

    return () => {
      clearInterval(pollSensorStatus);
    };
  }, []);

  useEffect(() => {
    if (terminalOperationStatus === OPERATION_STATUSES.MANUAL_OFFLINE) {
      clearInterval(pollSensorStatus);
    }
  }, [terminalOperationStatus])


  return <FatalErrorMessageFragment message={gettext('ATM is offline')} />;
};

export default Offline;
