import React, { useMemo } from 'react';
import size from 'lodash/size';
import { Typography } from '@material-ui/core';
import ExclamationIcon from '../../assets/images/exclamation.svg';
import CommonLayoutCenter from '../Layouts/CommonLayoutCenter';

const FatalErrorMessageFragment = ({ message = '' }) => {
  /**
   * Sometimes error message can be long, update font size
   */
  const fontSize = useMemo(() => (size(message) > 120 ? 48 : 56), [message]);

  return (
    <CommonLayoutCenter
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h2"
        color="textPrimary"
        className="font-weight-bold text-center"
        style={{ maxWidth: '80%', fontSize }}
      >
        {message}
      </Typography>
      <img
        src={ExclamationIcon}
        alt="test"
        style={{ position: 'absolute', left: '300px', width: 1000, right: 0, opacity: 0.1 }}
      />
    </CommonLayoutCenter>
  );
};

export default FatalErrorMessageFragment;
