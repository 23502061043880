/**
 * ADS related contexts
 */

import React, { useContext, useState } from 'react';

export const AdsContext = React.createContext({
  adsCollection: false,
  setAdsCollection: () => {},
});

AdsContext.displayName = 'Ads';

export const useAdsContext = () => {
  return useContext(AdsContext);
}; // to be called in components

export const useProvideAds = () => {
  const [adsCollection, setCollection] = useState({});

  const setAdsCollection = (lng, collection) => {
    console.log({
      adsCollection,
      newCollection: {
        ...adsCollection,
        [lng]: collection,
      },
    });
    setCollection({
      ...adsCollection,
      [lng]: collection,
    });
  };

  return {
    adsCollection,
    setAdsCollection,
  };
};

export const ProvideAds = ({ children }) => {
  const ads = useProvideAds();
  return <AdsContext.Provider value={ads}>{children}</AdsContext.Provider>;
};
