/**
 * Utilities does not belong to a particular component 
 */

/**
 * This utility will pause execution of next line for duration ms time
 * Should be use in combination with await
 * 
 * @example
 * > await sleep(3000)
 * > console.log("will trigger after 3 seconds");
 * 
 * @param {Number} duration - is ms
 * @returns {Promise}
 */
export const sleep = (duration) => new Promise(resolve => setTimeout(resolve, duration));