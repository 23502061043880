import React, { useContext, useEffect, useCallback } from 'react';
import includes from 'lodash/includes';
import { TransactionContext } from '../../contexts';
import { OPERATION_TYPES, CARD_READER_STATUSES } from '../../utils/constants';
import { dispatchCustomEventToSimulator, simulatorEvents } from '../../utils/simulatorUtils';
import { useCardContext } from '../../contexts/cardContexts';
import { useLocalize } from '../../localize';

export const AttachSimulatorDirector = () => {
  const { currentLanguage } = useLocalize();
  const { operationType } = useContext(TransactionContext);
  const { cardReaderStatus } = useCardContext();

  const handleResetShow = () => {
    dispatchCustomEventToSimulator(simulatorEvents.showBills, { detail: { show: false } });
    dispatchCustomEventToSimulator(simulatorEvents.showCard, { detail: { show: false } });
    dispatchCustomEventToSimulator(simulatorEvents.showPhone, { detail: { show: false } });
  };

  const handleShowItem = (simulatorEvent, show = true) => {
    dispatchCustomEventToSimulator(simulatorEvent, { detail: { show } });
  };

  useEffect(() => {
    // reset/remove all items if no operation type
    if (!operationType) {
      handleResetShow();
    }

    /**
     * Show the bills only while on deposit to card and send money flow
     */
    if (includes([OPERATION_TYPES.DEPOSIT_TO_CARD, OPERATION_TYPES.SEND], operationType)) {
      handleShowItem(simulatorEvents.showBills);
    }

    /**
     * Show the phone only during receive and send money flows
     */
    if (includes([OPERATION_TYPES.SEND, OPERATION_TYPES.RECEIVE], operationType)) {
      handleShowItem(simulatorEvents.showPhone);
    }

    /**
     * how the card only when card read status is waiting for card.
     */
    if (
      includes(
        [
          OPERATION_TYPES.WITHDRAW,
          OPERATION_TYPES.DEPOSIT_TO_CARD,
          OPERATION_TYPES.BALANCE_INQUIRY,
          OPERATION_TYPES.CHANGE_PIN,
          OPERATION_TYPES.CARD_TRANSACTION,
        ],
        operationType,
      )
    ) {
      handleShowItem(simulatorEvents.showCard);
    }
  }, [operationType]);

  useEffect(() => {
    if (cardReaderStatus) {
      /**
       * how the card only when card read status is waiting for card.
       */
      if (
        cardReaderStatus === CARD_READER_STATUSES.WAITING_FOR_CARD &&
        !(operationType === OPERATION_TYPES.SEND || operationType === OPERATION_TYPES.RECEIVE)
      ) {
        handleShowItem(simulatorEvents.showCard);
      } else {
        handleShowItem(simulatorEvents.showCard, false);
      }
    }
  }, [cardReaderStatus, operationType]);

  const dispatchCardEjectedSound = useCallback(() => {
    dispatchCustomEventToSimulator(simulatorEvents.playCardEjectSound, {
      detail: { language: currentLanguage },
    });
  }, [currentLanguage]);

  useEffect(() => {
    if (cardReaderStatus === CARD_READER_STATUSES.CARD_EJECTED) {
      dispatchCardEjectedSound();
    }
  }, [cardReaderStatus]);

  return <></>;
};
