import { makeStyles } from '@material-ui/styles';

const useLoadingComponentStyles = makeStyles({
  LoadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#ffffff38',
  },
});

export default useLoadingComponentStyles;
