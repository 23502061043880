import React from 'react';
import { ProvideChangePinContext } from './contexts/changePinContext';
// eslint-disable-next-line import/no-cycle
import { ProvideDeposit } from './contexts/depositContexts';
import { ProvideSensorsContext } from './contexts/sensorsContext';
import { ProvideWithdraw } from './contexts/withdrawContexts';

export const LanguageContext = React.createContext({
  currentLanguage:
    window.localStorage.getItem('language') || process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
  setCurrentLanguage: () => {},
});
LanguageContext.displayName = 'CurrentLanguage';

export const GoogleAnalyticsContext = React.createContext({
  gaInitialized: false,
  setGaInitialized: () => {},
});
GoogleAnalyticsContext.displayName = 'GoogleAnalyticsInit';

export const TransactionContext = React.createContext({
  transaction: {}, // store transaction data from api
  setTransaction: () => {},
  receiptDetails: {}, // store transaction data for receipt simulator
  setReceiptDetails: () => {},
  operationType: '', // store transaction type
  setOperationType: () => {},
});
TransactionContext.displayName = 'Transaction';

export * from './contexts/api';
export * from './contexts/cardContexts';

/**
 * This is experimental
 * TODO: move other provide context api here if no issue
 */
export const ProvideContextApiWrapper = ({ children }) => (
  <ProvideSensorsContext>
    <ProvideDeposit>
      <ProvideWithdraw>
        <ProvideChangePinContext>{children}</ProvideChangePinContext>
      </ProvideWithdraw>
    </ProvideDeposit>
  </ProvideSensorsContext>
);
