/* eslint-disable max-len */
import i18nIsoCountries from 'i18n-iso-countries';
import { get, defaultTo, keys } from 'lodash';

export const CARD_READ_TIMEOUT = defaultTo(process.env.REACT_APP_CARD_READ_TIMEOUT, 30);
export const SHOW_MINI_ACCOUNT_BALANCE =
  defaultTo(process.env.REACT_APP_SHOW_MINI_ACCOUNT_BALANCE, 'false') === 'true';
export const IDLE_TIMEOUT = defaultTo(process.env.REACT_APP_IDLE_TIMEOUT, 30);
export const INPUT_POLL_INTERVAL = process.env.REACT_APP_INPUT_POLL_INTERVAL || 500;

export const DEFAULT_AD_DURATION = defaultTo(process.env.REACT_APP_DEFAULT_AD_DURATION, 10);

export const LANGUAGE_STORAGE_KEY = 'language';

export const CURRENT_LANGUAGE_STORE =
  window.localStorage.getItem(LANGUAGE_STORAGE_KEY) || process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';

export const PO_TYPES = {
  CashTransfer: {
    code: 'CASHTRANSFER',
    display: 'Cash Transfer',
  },
};

export const OPERATION_TYPES = {
  SEND: 'send',
  RECEIVE: 'receive',
  WITHDRAW: 'withdraw',
  DEPOSIT_TO_CARD: 'deposit_to_card',
  BALANCE_INQUIRY: 'balance_inquiry',
  CHANGE_PIN: 'change_pin',
  CARD_TRANSACTION: 'card_transaction',
};

export const ERROR_MESSAGES = {
  GENERIC:
    'An error occurred. Please try again. If problem persists, please contact the system administrator',
  TX_NOT_FOUND: 'Transaction not found. Please make sure you entered the number correctly.',
  UNAUTHORIZED:
    'Sorry, your session has expired due to an extended period of inactivity. Please start over.',
  INVALID_BILL: 'Invalid bills detected. Please start over.',
};

export const codeErrorMessageMap = {
  403: ERROR_MESSAGES.UNAUTHORIZED,
  404: ERROR_MESSAGES.TX_NOT_FOUND,
};

/**
 * Method to get error message to show to the user
 * @param {String} code - error code, e.g. 400
 * @param {String} defaultMessage
 */
export const getErrorMessage = (code, defaultMessage = ERROR_MESSAGES.GENERIC) => {
  return get(codeErrorMessageMap, code, defaultMessage);
};

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/cs.json'));

export const defaultCurrency = 'CZK';

/**
 * Map for operation type mode if cash or card
 * always use values from OPERATION_TYPES for the keys
 */
export const operationTypeModeMap = {
  [OPERATION_TYPES.SEND]: 'cash',
  [OPERATION_TYPES.RECEIVE]: 'cash',
  [OPERATION_TYPES.DEPOSIT_TO_CARD]: 'card',
  [OPERATION_TYPES.WITHDRAW]: 'card',
  [OPERATION_TYPES.BALANCE_INQUIRY]: 'card',
  [OPERATION_TYPES.CHANGE_PIN]: 'card',
  [OPERATION_TYPES.CARD_TRANSACTION]: 'card',
};
export const CARD_READER_STATUSES = {
  0: 'NO_CARD',
  NO_CARD: 'NO_CARD',
  1: 'WAITING_FOR_CARD',
  WAITING_FOR_CARD: 'WAITING_FOR_CARD',
  2: 'HAS_CARD',
  HAS_CARD: 'HAS_CARD',
  3: 'CARD_EJECTING',
  CARD_EJECTING: 'CARD_EJECTING',
  4: 'CARD_EJECTED',
  CARD_EJECTED: 'CARD_EJECTED',
  5: 'CONTACTLESS_CARD_READ_ERROR',
  CONTACTLESS_CARD_READ_ERROR: 'CONTACTLESS_CARD_READ_ERROR',
};
export const CARD_STATUSES = {
  OK: 'OK',
  SELFBLOCKED: 'SELFBLOCKED',
  INVALID: 'INVALID',
  LOCALBLOCKED: 'LOCALBLOCKED',
  REMOTEBLOCKED: 'REMOTEBLOCKED',
};
export const LAST_CARD_STATUS = {
  OK: 'OK',
  CAPTURED: 'CAPTURED',
};

export const CARD_FLOWS = {
  INSERT: 'CARD_INSERT_FLOW',
  EJECT: 'CARD_EJECT_FLOW',
};

// this status is from backend - this does not mean withdraw status previous value
export const WITHDRAW_LAST_STATUS = {
  INITIAL: '',
  SUCCESS: 'SUCCESS',
  RETRACTED: 'RETRACTED',
};

export const MINIMUM_CARD_PIN_LENGTH = 4;
export const MAXIMUM_CARD_PIN_LENGTH = 6;

export const INPUT_KEY_MAP = {
  CLEAR: 'B',
};

export const CARD_READER_TYPES = {
  INITIAL: '',
  0: 'NONE',
  NONE: 'NONE',
  1: 'TRACK',
  TRACK: 'TRACK',
  2: 'CONTACTLESS',
  CONTACTLESS: 'CONTACTLESS',
};

export const BARCODE_STATUSES = {
  INITIAL: '',
  ERROR: 'ERROR',
  WAITING: 'WAITING',
  DONE: 'DONE',
  IDLE: 'IDLE',
};

export const BARCODE_VALIDATION_STATUSES = {
  OK: 'OK',
  TYPE_MISMATCH: 'TYPE_MISMATCH',
};

export const BARCODE_VALIDATION_TYPES = {
  TXID: 'TXID',
  SENDER_URL: 'SENDER_URL',
  NONE: 'NONE',
};

export const PIN_PAD_READER_TYPE = {
  PIN: 'PIN',
  AMOUNT: 'AMOUNT',
};

export const PIN_VALIDATION_STATUSES = {
  PIN_VALID: 1,
  PIN_INVALID: -1,
  CARD_CAPTURED: -2,
  UNAUTHORIZED_MAINTENANCE: -99,
};

/**
 * Machine Errors
 */
export const FATAL_ERRORS = {
  1006: 1006, // cash dispenser error - related to withdrawal
  1007: 1007, // cash dispenser error
};

/**
 * Receipt related statuses
 */
export const RECEIPT_PAPER_STATUSES = {
  OK: 'OK',
  PAPERJAM: 'PAPERJAM',
  OUTOFPAPER: 'OUTOFPAPER',
};

export const RECEIPT_PRINTER_STATUSES = {
  OK: 'OK',
  ERROR: 'ERROR',
};

export const DEPOSIT_STATUSES = {
  SHUTTER_CLOSED: 0,
  SHUTTER_OPEN: 1,
  COUNTING: 2,
};

export const DEPOSIT_ERROR_STATUSES = {
  IDLE: 0,
  ALL_BILL_INVALID: -1,
  INVALID_BILL: -2,
  TOO_MANY_BILL: -3,
  SHUTTER_OVERLOAD: -4,
};

/**
 * X-Currency are suspected counterfeit bills
 */
export const XCurrencyMapping = { XCZK: 'CZK', XEUR: 'EUR', XUSD: 'USD' };

export const XCurrencyKeys = keys(XCurrencyMapping);

export const CHANGE_PIN_STATUSES = {
  INITIAL: '',
  FAIL: -1,
  SUCCESS: 1,
  PENDING: 0, // not yet done; keep polling
};

export const FACE_DETECTION_STATUSES = {
  NOT_RECOGNIZED: -1,
  PROCESSING: 0,
  FACE_NEEDS_CONFIRMATION: 1,
  CARD_SELECTION: 2,
  DONE: 99,
  NO_FACE: -99,
};

/**
 * proximityStatus = 0 (present), 1 (not present), 2 (not available)
 */
export const PROXIMITY_STATUSES = {
  PRESENT: 0,
  NOT_PRESENT: 1,
  NOT_AVAILABLE: 2,
}

export const OPERATION_STATUSES = {
  OK: "OK",
  ERROR: "ERROR",
  MAINTENANCE: "MAINTENANCE",
  MANUAL_OFFLINE: "MANUAL_OFFLINE",
}