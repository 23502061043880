/**
 * CARD related contexts
 */

import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import { CARD_READER_TYPES } from '../utils/constants';

export const CardContext = React.createContext({
  beginCardReadCalled: false,
  setBeginCardReadCalled: () => {},
  cardInserted: false,
  setCardInserted: () => {},
  cardErrorMessage: '',
  setCardErrorMessage: () => {},
  cardErrorCode: '',
  setCardErrorCode: () => {},
  isCardReading: false,
  setIsCardReading: () => {},
  cardReaderStatus: '',
  setCardReaderStatus: () => {},
  cardStatus: '',
  setCardStatus: () => {},
  lastCardStatus: '',
  setLastCardStatus: () => {},
  cardStatusEnum: '',
  setCardStatusEnum: () => {},
  cardReaderType: '',
  setCardReaderType: () => {},
  isContactless: false,
  cardUser: '',
  setCardUser: () => {},
  isCardUserAuthenticated: false, // if user confirm from mobile using face detect feature
});

CardContext.displayName = 'Card';

export const useCardContext = () => {
  return useContext(CardContext);
}; // to be called in components

export const useProvideCard = () => {
  const [cardInserted, setCardInserted] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState('');
  const [cardErrorCode, setCardErrorCode] = useState();
  const [beginCardReadCalled, setBeginCardReadCalled] = useState(false);
  const [isCardReading, setIsCardReading] = useState(false);
  const [cardReaderStatus, setCardReaderStatus] = useState();
  const [cardStatus, setCardStatus] = useState('');
  // this last card status is from backend - this doesn't mean cardReaderStatus previous value
  const [lastCardStatus, setLastCardStatus] = useState('');
  const [cardStatusEnum, setCardStatusEnum] = useState();
  const [cardReaderType, setCardReaderType] = useState('');
  // if using face detect feature
  const [cardUser, setCardUser] = useState({});

  return {
    cardInserted,
    setCardInserted,
    cardErrorMessage,
    setCardErrorMessage,
    cardErrorCode,
    setCardErrorCode,
    isCardReading,
    setIsCardReading,
    beginCardReadCalled,
    setBeginCardReadCalled,
    cardReaderStatus,
    setCardReaderStatus,
    cardStatus,
    setCardStatus,
    lastCardStatus,
    setLastCardStatus,
    cardStatusEnum,
    setCardStatusEnum,
    isContactless: cardReaderType === CARD_READER_TYPES.CONTACTLESS,
    cardReaderType,
    setCardReaderType,
    cardUser,
    setCardUser,
    isCardUserAuthenticated: !!get(cardUser, "firstName", "")
  };
};

export const ProvideCard = ({ children }) => {
  const card = useProvideCard();
  return <CardContext.Provider value={card}>{children}</CardContext.Provider>;
};
