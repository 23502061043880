/**
 * SENSORS related contexts
 */
import React, { useContext, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { FACE_DETECTION_STATUSES, PROXIMITY_STATUSES } from '../utils/constants';

const initialState = {
  proximityStatus: PROXIMITY_STATUSES.PRESENT,
  faceDetectionStatus: FACE_DETECTION_STATUSES.NO_FACE,
  alwaysShowPreview: false,
}

const initialStateAfterRetry = {
  proximityStatus: PROXIMITY_STATUSES.PRESENT,
  faceDetectionStatus: FACE_DETECTION_STATUSES.PROCESSING,
  alwaysShowPreview: true,
}

export const SensorsContext = React.createContext({
  proximityStatus: initialState.proximityStatus,
  setProximityStatus: () => { },
  faceDetectionStatus: initialState.faceDetectionStatus,
  setFaceDetectionStatus: () => { },
  resetSensorsState: () => { },
  resetSensorsStateAfterRetry: () => { },
  alwaysShowPreview: initialState.alwaysShowPreview,
});

SensorsContext.displayName = 'Sensors';

// to be called in components
export const useSensorsContext = () => useContext(SensorsContext)

export const ProvideSensorsContext = ({ children }) => {
  const [proximityStatus, setProximityStatus] = useState(initialState.proximityStatus);
  const [faceDetectionStatus, setFaceDetectionStatus] = useState(initialState.faceDetectionStatus);
  const [alwaysShowPreview, setAlwaysShowPreview] = useState(initialState.alwaysShowPreview);

  const resetSensorsState = useCallback(
    () => {
      ReactDOM.unstable_batchedUpdates(() => {
        setProximityStatus(initialState.proximityStatus);
        setFaceDetectionStatus(initialState.faceDetectionStatus);
        setAlwaysShowPreview(initialState.alwaysShowPreview);
      });
    },
    [],
  )

  /**
   * Method called after reset face detect api is called
   */
  const resetSensorsStateAfterRetry = useCallback(
    () => {
      ReactDOM.unstable_batchedUpdates(() => {
        setProximityStatus(initialStateAfterRetry.proximityStatus);
        setFaceDetectionStatus(initialStateAfterRetry.faceDetectionStatus);
        setAlwaysShowPreview(initialStateAfterRetry.alwaysShowPreview);
      });
    },
    [],
  )

  return (
    <SensorsContext.Provider
      value={{
        proximityStatus,
        setProximityStatus,
        faceDetectionStatus,
        setFaceDetectionStatus,
        resetSensorsState,
        resetSensorsStateAfterRetry,
        alwaysShowPreview,
      }}
    >
      {children}
    </SensorsContext.Provider>
  );
};
