import get from "lodash/get";
import { atmTokenName, Storage } from "./http";

/**
 * Set token from HTTP response
 *
 * @param {Object} response - http response
 * @param {String} key - key to get from response for token
 */
export const setJwtTokenIfExists = async (response, key = "token") => {
  const newToken = get(response, key);
  if (newToken) {
    await Storage.setItem(atmTokenName, `JWT ${newToken}`);
    console.log(`NEW JWT ${newToken}`);
  }
}
