import _get from 'lodash/get';
import { useState, useEffect, useMemo, useContext } from 'react';
import usePrevious from './hooksUtils/usePrevious';
import { TransactionContext } from '../contexts';
// eslint-disable-next-line import/no-cycle
import useApi from '../api/api';
import { Storage } from '../api/http';
import { defaultTo, each, keys, replace, size } from 'lodash';

export const SIMULATOR_DEPOSITED_BILLS_STORAGE_KEY = 'simulatorDepositedBill';

export const simulatorEvents = {
  'open-door': 'open-door',
  'close-door': 'close-door',
  'start-deposit': 'start-deposit',
  'start-timeout': 'start-timeout',
  'place-qr': 'place-qr',
  'scan-qr': 'scan-qr',
  'finished-qr': 'finished-qr',
  'dispense-money': 'dispense-money',
  'cash-withdrawn': 'cash-withdrawn',
  receiptSms: 'receipt-sms',
  receipt: 'receipt',
  throwReceipt: 'throw-receipt',
  code: 'code',
  reset: 'reset',
  CustomKeyboardEvent: 'CustomKeyboardEvent',
  cardInserted: 'card-inserted',
  cardDispense: 'card-dispense',
  cardRemoved: 'card-removed',
  cardForceReset: 'card-force-reset',
  operationType: 'operationType',
  lightBlinkOn: 'light-blink-on',
  lightBlinkOff: 'light-blink-off',
  showPhone: 'show-phone',
  showCard: 'show-card',
  showBills: 'show-bills',
  changeLanguage: 'change-language',
  playCardEjectSound: 'play-card-eject-sound',
};

/**
 *
 * @param {string} event
 */
export const dispatchCustomEventToSimulator = (event, payload = {}) => {
  window.parent.document.dispatchEvent(new CustomEvent(event, { ...payload }));
};

export const dispatchReceiptEventToSimulator = (detail) => {
  dispatchCustomEventToSimulator(simulatorEvents.receipt, { detail });
};

export const dispatchCodeEventToSimulator = () => {
  dispatchCustomEventToSimulator(simulatorEvents.code, {
    detail: process.env.REACT_APP_SAMPLE_TRANSACTION_CODE.split(/(\d{3})/)
      .filter((x) => x)
      .join(' '),
  });
};

const validSimulatorInput = (value) => {
  return '123456789000'.includes(value);
};

export const useSimulatorEventDispatcher = () => {
  const { receiptDetails, operationType } = useContext(TransactionContext);

  const dispatchReceiptEvent = () => {
    dispatchCustomEventToSimulator(simulatorEvents.receipt, {
      detail: {
        operationType,
        ...receiptDetails,
      },
    });
  };

  const dispatchResetEvent = () => {
    console.log('reset event dispatche');
    dispatchCustomEventToSimulator(simulatorEvents.reset);
  };

  return {
    dispatchReceiptEvent,
    dispatchResetEvent,
  };
};

export const useSimulatorGenericEventListener = (event, autoAttach = true) => {
  const [tick, setTick] = useState(false);
  const [data, setData] = useState();
  const prevTick = usePrevious(tick);

  useEffect(() => {
    const isInsideIFrame = window !== window.parent;
    const parentDoc = window.parent.document;
    const genericEvent = (eventData) => {
      setTick(true);
      setData(eventData);
      setTick(false);
    };

    if (isInsideIFrame && autoAttach) {
      parentDoc.addEventListener(event, genericEvent);
    }
    return () => {
      if (isInsideIFrame && autoAttach) {
        parentDoc.removeEventListener(event, genericEvent);
      }
    };
  }, [autoAttach, event]);

  return {
    event,
    data,
    triggered: useMemo(() => !tick && prevTick, [tick, prevTick]),
  };
};

export const useSimulatorKeyboardEvent = (autoAttach) => {
  const [tick, setTick] = useState(false);
  const [isValue, setIsValue] = useState(false);
  const [value, setValue] = useState('');
  const { inputKey } = useApi();

  const prevTick = usePrevious(tick);

  useEffect(() => {
    const isInsideIFrame = window !== window.parent;
    const numpadWrapper = window.parent.document.getElementsByClassName('numpad__wrapper')[0];

    const SimulatorClickEventMethod = (e) => {
      const catchValue = _get(e, 'target.value', '');
      window.document.dispatchEvent(
        new CustomEvent(simulatorEvents.CustomKeyboardEvent, { detail: { value: catchValue } }),
      );
    };
    const CustomKeyboardEventMethod = (e) => {
      const evtValue = _get(e, 'detail.value');
      if (evtValue) {
        try {
          setTick(true);
          setValue(evtValue);
          inputKey(evtValue);
          setIsValue(validSimulatorInput(evtValue));
        } catch (error) {
          console.log('useSimulatorKeyboardEvent:error', error);
        } finally {
          setTick(false);
        }
      }
    };
    if (isInsideIFrame && autoAttach) {
      if (numpadWrapper) {
        numpadWrapper.addEventListener('click', SimulatorClickEventMethod);
      }
      window.document.addEventListener(
        simulatorEvents.CustomKeyboardEvent,
        CustomKeyboardEventMethod,
      );
    }
    return () => {
      window.document.removeEventListener(
        simulatorEvents.CustomKeyboardEvent,
        CustomKeyboardEventMethod,
      );
      if (numpadWrapper) {
        numpadWrapper.removeEventListener('click', SimulatorClickEventMethod);
      }
    };
  }, [autoAttach, inputKey]);

  return {
    value,
    tick,
    prevTick,
    isValue,
  };
};

export const simulatorDispatchCardConfiscated = (operationType) => {
  dispatchCustomEventToSimulator(simulatorEvents.receipt, {
    detail: {
      operationType,
      message: 'Card confiscated. Please contact support',
    },
  });
};

export const simulatorDispenseDepositedBills = () => {
  // format is {"bill_200":1,"bill_500":1}
  const depositedBills = JSON.parse(
    defaultTo(Storage.getItem(SIMULATOR_DEPOSITED_BILLS_STORAGE_KEY), '{}'),
  );

  // parse format to {"200":1,"500":1} - without prefix bill_
  let parsedDepositedBills = {};
  if (size(keys(depositedBills)) > 0) {
    each(depositedBills, (billCount, billRaw) => {
      parsedDepositedBills[replace(billRaw, 'bill_', '')] = billCount;
    });
  }

  // dispatch to simulator
  dispatchCustomEventToSimulator('dispense-money', {
    detail: {
      count: parsedDepositedBills,
    },
  });
};
