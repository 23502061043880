import React from 'react';
import keys from 'lodash/keys';
import map from 'lodash/map';
import replace from 'lodash/replace';
import { Route, Switch } from 'react-router';
import { compose } from 'recompose';
import {
  AsyncLanding,
  AsyncStart,
  AsyncScan,
  AsyncBalanceInquiry,
  AsyncCardDone,
  AsyncCardEjected,
  AsyncSendMoney,
  AsyncTransferSummary,
  AsyncTransferSuccess,
  AsyncDeposit,
  AsyncReceiveMoney,
  AsyncDepositToCard,
  AsyncMessage,
  AsyncScanQR,
  AsyncWithdraw,
  AsyncLanguage,
  AsyncTransferNew,
  AsyncTestPage,
  AsyncSamplePage,
  AsyncSimulator,
  AsyncWithdrawFromCard,
  AsyncCardTransaction,
  AsyncSuccessThankYou,
  AsyncCancelBuffer,
  AsyncCardReadError,
  AsyncChangePin,
  AsyncErrorMessage,
  AsyncHomeBuffer,
  AsyncNoReceipt,
  AsyncSuspectedCounterfeitBills,
  AsyncMaintenance,
  AsyncDepositErrorInvalidBills,
} from './asyncLoaders';
import Offline from '../containers/Offline';

const ENV_MODE = process.env.REACT_APP_ENV || process.env.NODE_ENV;

// ----------------URL PATHS----------------//
export const urlPaths = (() => {
  const rawUrlPaths = {
    atm: '/',
    home: '/screen',
    homeBuffer: '/screen/home-buffer',
    offline: '/screen/offline',
    start: '/screen/start',
    scan: '/screen/scan',
    balanceInquiry: '/screen/balance-inquiry',
    cardEjected: '/screen/card-ejected',
    cardDone: '/screen/card-done',
    cardReadError: '/screen/card-read-error',
    sendMoney: '/screen/send-money',
    scanQR: '/screen/scan-qr',
    receiveMoney: '/screen/receive-money',
    transferSummary: '/screen/transfer-summary',
    transferSuccess: '/screen/transfer-success',
    message: '/screen/message',
    deposit: '/screen/deposit',
    depositToCard: '/screen/deposit-to-card',
    depositErrorInvalidBills: '/screen/deposit-error/invalid-bills',
    withdraw: '/screen/withdraw',
    testPage: '/screen/test',
    language: '/screen/language',
    transferNew: '/screen/transfer-new',
    sample: '/screen/sample',
    withdrawFromCard: '/screen/withdraw-from-card',
    cardTransaction: '/screen/card-transaction',
    changePin: '/screen/change-pin',
    successThankYou: '/screen/success-thank-you',
    cancelBufferPage: '/screen/cancel-buffer',
    errorMessage: '/screen/error',
    noReceipt: '/screen/no-receipt',
    suspectedCounterfeitBills: '/screen/suspected-counterfeit-bills',
    maintenance: '/screen/maintenance',
  };

  if (ENV_MODE === 'production') {
    // if env is production, remove "/screen" from urls
    map(keys(rawUrlPaths), (key) => {
      rawUrlPaths[key] = replace(rawUrlPaths[key], '/screen', '');
    });
    rawUrlPaths.home = '/';
  }

  return rawUrlPaths;
})();

export const urlPathsWithParams = {
  cardTransaction: `${urlPaths.cardTransaction}/:page?`,
  balanceInquiry: `${urlPaths.balanceInquiry}/:page?`,
  deposit: `${urlPaths.deposit}/:page(1|2)?/:txId?`,
  depositToCard: `${urlPaths.depositToCard}/:page?`,
  withdraw: `${urlPaths.withdraw}/:txId?`,
  transferSummary: `${urlPaths.transferSummary}/:txId?`,
  transferNew: `${urlPaths.transferNew}/:page?`,
  scan: `${urlPaths.scan}/:page?`,
  sendMoney: `${urlPaths.sendMoney}/:page?`,
  changePin: `${urlPaths.changePin}/:page?`,
  scanQR: `${urlPaths.scanQR}/:page?`,
  maintenance: `${urlPaths.maintenance}/:page?`,
  testPage: `${urlPaths.testPage}/:page`,
  sample: `${urlPaths.sample}/:page`,
};

const SamplePage = compose()(AsyncSamplePage);
const LandingPage = compose()(AsyncLanding);
const StartPage = compose()(AsyncStart);
const OfflinePage = Offline;
const ScanPage = compose()(AsyncScan);
const BalanceInquiryPage = compose()(AsyncBalanceInquiry);
const CardDonePage = compose()(AsyncCardDone);
const CardEjectedPage = compose()(AsyncCardEjected);
const SendMoneyPage = compose()(AsyncSendMoney);
const TransferSummaryPage = compose()(AsyncTransferSummary);
const TransferSuccessPage = compose()(AsyncTransferSuccess);
const DepositPage = compose()(AsyncDeposit);
const ReceiveMoneyPage = compose()(AsyncReceiveMoney);
const DepositToCardPage = compose()(AsyncDepositToCard);
const DepositErrorInvalidBillsPage = compose()(AsyncDepositErrorInvalidBills);
const MessagePage = compose()(AsyncMessage);
const ScanQrPage = compose()(AsyncScanQR);
const WithdrawPage = compose()(AsyncWithdraw);
const TransferNewPage = compose()(AsyncTransferNew);
const LanguagePage = compose()(AsyncLanguage);
const TestPage = compose()(AsyncTestPage);
const Simulator = compose()(AsyncSimulator);
const WithdrawFromCardPage = compose()(AsyncWithdrawFromCard);
const CardTransactionPage = compose()(AsyncCardTransaction);
const ChangePinPage = compose()(AsyncChangePin);
const SuccessThankYouPage = compose()(AsyncSuccessThankYou);
const CancelBufferPage = compose()(AsyncCancelBuffer);
const CardReadErrorPage = compose()(AsyncCardReadError);
const ErrorMessage = compose()(AsyncErrorMessage);
const HomeBufferPage = compose()(AsyncHomeBuffer);
const NoReceiptPage = compose()(AsyncNoReceipt);
const MaintenancePage = compose()(AsyncMaintenance);

// ----------------ROUTES----------------//

const RootRoute = ({ children }) =>
  ENV_MODE === 'production' ? (
    <>{children}</>
  ) : (
    <Switch>
      <Route path="/screen">{children}</Route>
      <Route path="/" exact component={Simulator} />
      <Route path="/maintenance" component={Simulator} />
    </Switch>
  );

const Routes = (() => {
  return (
    <RootRoute>
      <div id="root-start">
        <Switch>
          <Route exact path={urlPaths.home} component={LandingPage} />
          <Route path={urlPaths.start} component={StartPage} />
          <Route path={urlPaths.offline} component={OfflinePage} />
          <Route path={urlPathsWithParams.scan} component={ScanPage} />
          <Route path={urlPathsWithParams.balanceInquiry} component={BalanceInquiryPage} />
          <Route path={urlPaths.cardDone} component={CardDonePage} />
          <Route path={urlPaths.cardEjected} component={CardEjectedPage} />
          <Route path={urlPathsWithParams.sendMoney} component={SendMoneyPage} />
          <Route path={urlPathsWithParams.transferSummary} component={TransferSummaryPage} />
          <Route path={urlPaths.transferSuccess} component={TransferSuccessPage} />
          <Route path={urlPathsWithParams.deposit} component={DepositPage} />
          <Route path={urlPaths.receiveMoney} component={ReceiveMoneyPage} />
          <Route path={urlPathsWithParams.depositToCard} component={DepositToCardPage} />
          <Route exact path={urlPaths.depositErrorInvalidBills} component={DepositErrorInvalidBillsPage} />
          <Route path={urlPathsWithParams.scanQR} component={ScanQrPage} />
          <Route path={urlPathsWithParams.withdraw} component={WithdrawPage} />
          <Route path={urlPaths.language} component={LanguagePage} />
          <Route path={urlPathsWithParams.transferNew} component={TransferNewPage} />
          <Route path={urlPathsWithParams.testPage} component={TestPage} />
          <Route path={urlPathsWithParams.sample} component={SamplePage} />
          <Route path={urlPaths.withdrawFromCard} component={WithdrawFromCardPage} />
          <Route path={urlPathsWithParams.cardTransaction} component={CardTransactionPage} />
          <Route path={urlPathsWithParams.changePin} component={ChangePinPage} />
          <Route path={urlPaths.successThankYou} component={SuccessThankYouPage} />
          <Route path={urlPaths.message} component={MessagePage} />
          <Route path={urlPaths.cancelBufferPage} component={CancelBufferPage} />
          <Route path={urlPaths.cardReadError} component={CardReadErrorPage} />
          <Route path={urlPaths.errorMessage} component={ErrorMessage} />
          <Route path={urlPaths.homeBuffer} component={HomeBufferPage} />
          <Route path={urlPaths.noReceipt} component={NoReceiptPage} />
          <Route path={urlPaths.suspectedCounterfeitBills} component={AsyncSuspectedCounterfeitBills} />
          <Route path={urlPathsWithParams.maintenance} component={MaintenancePage} />
        </Switch>
      </div>
    </RootRoute>
  );
})();

export default Routes;
