/**
 * DEPOSITS related contexts
 */

import React, { useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { getDepositsTotal } from '../utils/depositUtil';

export const DepositContext = React.createContext({
  depositStatus: '',
  setDepositStatus: () => {},
  deposits: [],
  setDeposits: () => {},
  otherDeposits: [],
  setOtherDeposits: () => {},
  simulatorDoorOpened: false,
  setSimulatorDoorOpened: () => {},
  depositReversible: true,
  setDepositReversible: () => {},
  canAddMore: true,
  setCanAddMore: () => {},
  suspectedCounterfeitBills: [],
  setSuspectedCounterfeitBills: () => {},
  isSuspectedCounterfeitBills: false,
  isCardTransaction: false,
  setIsCardTransaction: () => {},
  totalDeposited: 0,
});

DepositContext.displayName = 'Deposit';

export const useDepositContext = () => {
  return useContext(DepositContext);
}; // to be called in components

export const useProvideDeposit = () => {
  const [depositStatus, setDepositStatus] = useState('');
  const [deposits, setDeposits] = useState([]);
  const [otherDeposits, setOtherDeposits] = useState([]); // for other currency deposits
  const [simulatorDoorOpened, setSimulatorDoorOpened] = useState(false);
  const [depositReversible, setDepositReversible] = useState(true);
  const [canAddMore, setCanAddMore] = useState(true);
  const [suspectedCounterfeitBills, setSuspectedCounterfeitBills] = useState(false);
  const [isCardTransaction, setIsCardTransaction] = useState(false);

  return {
    depositStatus,
    setDepositStatus,
    deposits,
    setDeposits,
    otherDeposits,
    setOtherDeposits,
    totalDeposited: getDepositsTotal(deposits),
    simulatorDoorOpened,
    setSimulatorDoorOpened,
    depositReversible,
    setDepositReversible,
    canAddMore,
    setCanAddMore,
    suspectedCounterfeitBills,
    setSuspectedCounterfeitBills,
    /**
     * X-Currency are suspected counterfeit bills
     */
    isSuspectedCounterfeitBills: !isEmpty(suspectedCounterfeitBills),
    isCardTransaction,
    setIsCardTransaction,
  };
};

export const ProvideDeposit = ({ children }) => {
  const deposit = useProvideDeposit();
  return <DepositContext.Provider value={deposit}>{children}</DepositContext.Provider>;
};
