import { urlPaths } from '../../router/routes';

export const maintenanceUrls = {
  entered: `${urlPaths.maintenance}/default`,
  unauthorized: `${urlPaths.maintenance}/unauthorized`,
  captureCard: `${urlPaths.maintenance}/captured-card`,
  exchangeCassettes: `${urlPaths.maintenance}/exchange-cassettes`,
  replaceReceiptRoll: `${urlPaths.maintenance}/replace-receipt-roll`,
  cancelBuffer: `${urlPaths.maintenance}/maintenance-cancel-buffer`
};
