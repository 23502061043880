import i18nIsoCountries from 'i18n-iso-countries';
import get from "lodash/get";
import { defaultCurrency } from '../../utils/constants';
import { money } from '../../utils/formatters';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export function getCountryName(countryCode, language) {
  return i18nIsoCountries.getName(countryCode.toUpperCase(), language || 'en');
}

export function roundUp(amount, nearest) {
  return Math.ceil(amount / nearest) * nearest;
}

/**
 * Process incoming transaction
 *
 * forexCalculator should return format:
 * {Object} - {"currency": String, "amount": Float, "crossRate": Float, "rateCalculationID": Int}
 *
 * @typedef {Object} ForexCalculator
 * @property {Number} Amount - decimal
 * @property {String} OperationCode - SELL/BUY (optional - default "SELL")
 * @property {String} ToCurrencyCode
 * @property {String} FromCurrencyCode - (optional - default "CZK")
 *
 * @param {Object} transactionInformation
 * @param {ForexCalculator} forexCalculator
 */

export const processForexTransaction = async (transactionInformation, forexCalculator) => {
  const toCurrency = get(transactionInformation, "ToCurrency");
  const fromCurrency = get(transactionInformation, "FromCurrency", "CZK");

  // if toCurrency is defaultCurrency (CZK) it's not a forex transaction see #3zvctx
  if (toCurrency === defaultCurrency) {
    return {};
  }

  if (toCurrency !== defaultCurrency) {
    const FromAmount = get(transactionInformation, "FromAmount");
    const ToAmount = get(transactionInformation, "ToAmount");
    const MPFee = get(transactionInformation, "MPFee");
    const MPFeeCurrency = get(transactionInformation, "MPFeeCurrency");

    if (fromCurrency === defaultCurrency) {
      const forexData = await forexCalculator({
        Amount: ToAmount,
        ToCurrencyCode: toCurrency,
        FromCurrencyCode: fromCurrency
      });

      return {
        ...forexData,
        Sending: `${money(FromAmount)} ${fromCurrency}`,
        Fee: MPFee ? `${money(MPFee)} ${MPFeeCurrency}` : 'No Fee',
        ReceiverGets: `${money(forexData.amount)} ${forexData.currency}`,
        Rate: `1 ${defaultCurrency} = ${money(forexData.crossRate)} ${forexData.currency}`
      }
    }
    if (fromCurrency !== defaultCurrency) {
      // round to the nearest whole number because api will throw an error
      const Amount = roundUp((Number(MPFee) + Number(ToAmount)), 1);

      const forexData = await forexCalculator({
        Amount,
        ToCurrencyCode: toCurrency,
        FromCurrencyCode: defaultCurrency,
        OperationCode: "BUY"
      });

      const rate = get(forexData, "crossRate", 0);
      const totalAmount = get(forexData, "amount");
      // rate from default currency (CZK), uses / because of BUY
      const rateFromDefaultCurrency = Number(ToAmount) / Number(totalAmount) > 1 ? rate : 1 / Number(rate);
      const sendingAmount = Number(ToAmount) / rateFromDefaultCurrency;
      const forexCurrency = get(forexData, "currency");
      const fee = totalAmount - sendingAmount;          // because fee is part of the requested amount

      return {
        ...forexData,
        Sending: `${money(sendingAmount)} ${forexCurrency}`,
        Fee: MPFee ? `${money(fee)} ${forexCurrency}` : 'No Fee',
        ReceiverGets: `${money(ToAmount)} ${toCurrency}`,
        Rate: `1 ${defaultCurrency} = ${money(rateFromDefaultCurrency)} ${toCurrency}`,
        toDeposit: roundUp(totalAmount, 100), // to be used as checking
        fee,                                  // fee raw amount without currency
        sendingAmount                         // raw sending amount computed with rate
      };
    }
  }

  return {};
}
