import { useCallback, useContext } from "react";
import get from "lodash/get";
import noop from "lodash/noop";
import { useHistory } from "react-router";
import { useCustomSnackbar } from "../components/Snackbar";
import { getErrorMessage, CARD_READER_TYPES } from "../utils/constants";
import { urlPaths } from "../router/routes";
import { POST, Storage, atmTokenName } from "./http";
import { ENDPOINTS } from "./endpoints";
import { CardContext } from "../contexts";
// eslint-disable-next-line import/no-cycle
import { dispatchCustomEventToSimulator, simulatorEvents } from "../utils/simulatorUtils";
import { maintenanceUrls } from "../containers/Maintenance/maintenanceUrls";
// eslint-disable-next-line import/no-cycle
import { useLocalize } from "../localize";

export const useErrorHandler = ({ setLoading = noop }) => {
  const { gettext } = useLocalize();
  const history = useHistory();
  const { setCardInserted, setBeginCardReadCalled, setIsCardReading, setCardReaderType } = useContext(CardContext);
  const { showWarning } = useCustomSnackbar();

  /**
   * This method is internal and should reflect api.js cancel method
   * Do not call cancel() method from api.js here - it will cause circular dependency
   */
  const internalCancel = async () => {
    // TODO: check if already cancelling

    console.log("Something went wrong, resetting card statuses!!!");
    setCardInserted(false);
    setBeginCardReadCalled(false);
    setIsCardReading(false);
    setCardReaderType(CARD_READER_TYPES.INITIAL);
    dispatchCustomEventToSimulator(simulatorEvents.cardForceReset);

    POST(ENDPOINTS.CANCEL).then(response => {
      console.log('internalCancel:response', response);

      // TODO: update api context: cancelled
    }).catch(error => {
      // TODO: update what to do when error on cancel api
      console.log('internalCancel:error', error);

      // TODO: update api context: cancelled
    });
  };

  /**
   * Use this method as callback method
   *
   * http.js: POST (url, data, onError)
   * http.js: GET (url, onError)
   */
  const onError = useCallback(
    (err, res, shouldRedirectToHome = true) => {
      setLoading(false);
      // on api error
      const statusCode = get(res, 'status');
      const errMsg = getErrorMessage(statusCode);

      setTimeout(() => showWarning(errMsg, `error-${res.status}`), 500);
      internalCancel();
      if (shouldRedirectToHome) {
        history.push(urlPaths.homeBuffer);
      }
    },
    [history, showWarning, setLoading],
  );

  /**
   * In use for sensor status, if an 401 error occurs, clear jwt token that's causing the error
   * return true if error is handled or false if otherwise
   */
  const onErrorClearJwtToken = (err, res) => {
    const statusCode = get(res, 'status');
    if (statusCode === 401) {
      Storage.removeItem(atmTokenName);
      return true;
    }

    return false;
  }

  /**
   * if an 400 error occurs, get error message from err object then show error
   */
  const onErrorPin = useCallback(
    (err, res) => {
      const statusCode = get(res, 'status');
      if (statusCode === 400) {
        const response = JSON.parse(get(res, "request.response", "{}"));
        const msg = get(response, "card_pin") || get(res, "statusText", "");
        showWarning(msg);
        return true;
      }

      return false;
    },
    [showWarning],
  )

  const onErrorMaintenanceBeginCommon = useCallback(
    (err, res) => {
      const statusCode = get(res, 'status');
      if (statusCode && statusCode === 403) {
        setTimeout(() => showWarning(gettext("Please wait, not in Idle state!")), 500);
        history.push(maintenanceUrls.entered);
      } else {
        console.log({ err, res });
      }
    },
    [history, showWarning, gettext],
  )

  const onErrorMaintenanceCancel = useCallback(
    (err, res) => {
      const statusCode = get(res, 'status');
      if (statusCode && statusCode === 403) {
        setTimeout(() => showWarning(gettext("Please wait, not in cancellable state!")), 500);
        history.push(maintenanceUrls.entered);
      } else if (statusCode && statusCode >= 500) {
        setTimeout(() => showWarning(gettext("Internal Error")), 500);
        history.push(maintenanceUrls.entered);
      }
    },
    [history, showWarning, gettext],
  )

  return {
    onError,
    onErrorClearJwtToken,
    onErrorPin,
    onErrorMaintenanceBeginCommon,
    onErrorMaintenanceCancel,
  }
}
