import moneyLocalFormatter from '@dynameyes/js-utils/moneyLocalFormatter';
import { defaultTo, each, get, keys, round, toNumber, uniq, replace } from 'lodash';
import { CURRENT_LANGUAGE_STORE, LANGUAGE_STORAGE_KEY } from './constants';
import { Storage } from '../api/http';

/**
 * Method to format money to local
 *
 * @param {Number} amount
 */
export const money = (amount, decimals = 2) => {
  /**
   * Make sure that falsy value is not 0
   * Because 0 is a valid amount value
   */
  if (!amount && amount !== 0) return '';

  /**
   * select language from local storage
   * use CURRENT_LANGUAGE_STORE as fallback value
   */
  const language = defaultTo(Storage.getItem(LANGUAGE_STORAGE_KEY) , CURRENT_LANGUAGE_STORE)
  const formatted = moneyLocalFormatter(amount, decimals, true, language);
  if (language === 'en') {
    /**
     * override if language is en, use space as thousands delimiter
     */
    return replace(formatted, ',', ' ');
  }
  return formatted;
};

/**
 * Combine objects, add if value if number, useful for deposited-add more
 * @param {Object} objA
 * @param {Object} objB
 */
export const combineAddValue = (objA, objB) => {
  const allKeys = uniq([...keys(objA), ...keys(objB)]);
  let newData = {};
  each(allKeys, (key) => {
    const valA = get(objA, key, '');
    const valB = get(objB, key, '');
    if (!isNaN(toNumber(valA)) && !isNaN(toNumber(valB))) {
      newData[key] = toNumber(valA) + toNumber(valB);
    } else {
      console.log('combineAddValue: Value is not a number', {
        valA: toNumber(valA),
        valB: toNumber(valB),
      });
    }
  });
  return newData;
};

/**
 * Create a display exchange rate using data from request and response
 *
 * Note:
 * It is expected that proxy will only return only positive crossRate
 * that is why it has to be checked if cross-rate multiplied by the amount
 * is the same as with the returned amount
 *
 * @param {Object} request
 * @param {Object} response
 * @returns
 */
export const forexCalculateRateDisplay = (request, response) => {
  const requestAmount = get(request, "amount", 100);
  const amount = get(response, "amount");
  const crossRate = get(response, "crossRate");
  const currency = get(response, "currency");

  if (!amount || !crossRate) {
    // this will not likely happen
    console.error("forexCalculateRateDisplay returned an empty string, context", {
      request, response
    })
    return "";
  }

  const isBaseCurrencyCZK = round(requestAmount * crossRate) === round(amount);
  const baseCurrency = isBaseCurrencyCZK ? "CZK" : currency;
  const toCurrency = isBaseCurrencyCZK ? currency : "CZK";

  return `1 ${baseCurrency} = ${crossRate} ${toCurrency}`;
}
