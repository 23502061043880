export const customLoaderStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    minHeight: 'inherit',
    padding: '1.25rem',
  },
  messageBox: {
    marginTop: '1rem',
  },
});
