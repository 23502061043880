import { defaultTo, get } from 'lodash';
import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import { ENDPOINTS } from '../../api/endpoints';
import { GET } from '../../api/http';
import { useApiContext } from '../../contexts';
import { urlPaths } from '../../router/routes';
import { OPERATION_STATUSES } from '../../utils/constants';
import usePrevious from '../../utils/hooksUtils/usePrevious';
import { usePoll } from './usePoll';

const pollInterval = defaultTo(process.env.REACT_APP_MACHINE_INFO_POLL_INTERVAL, 500)

const getMachineInfo = () => {
  return new Promise((resolve) => {
    GET(ENDPOINTS.GET_MACHINE_INFO).then(response => resolve(response)).catch((error) => setTimeout(resolve, 1000, error))
  })
}

const AttachTerminalStatusChecking = () => {
  const { push } = useHistory();
  const { setTerminalOperationStatus } = useApiContext();
  const { response } = usePoll(true, getMachineInfo, pollInterval);
  const operationStatus = get(response, "operation_status");
  const prevOperationStatus = usePrevious(operationStatus);

  useEffect(() => {
    setTerminalOperationStatus(operationStatus);

    if (operationStatus === OPERATION_STATUSES.MANUAL_OFFLINE) {
      // redirect to offline page
      push(urlPaths.offline);
    } else if (operationStatus === OPERATION_STATUSES.OK && prevOperationStatus === OPERATION_STATUSES.MANUAL_OFFLINE) {
      // check if previous operation status is MANUAL_OFFLINE redirect to start page if already OK
      push(urlPaths.start);
    }

  }, [operationStatus, push, prevOperationStatus])

  return <></>;
}

export default AttachTerminalStatusChecking
