/**
 * API related contexts
 */

import { includes, isArray } from 'lodash';
import React, { useContext, useState, useCallback, useMemo } from 'react';
import { FATAL_ERRORS, OPERATION_STATUSES, RECEIPT_PAPER_STATUSES, RECEIPT_PRINTER_STATUSES } from '../utils/constants';

export const CANCEL_STATUSES = {
  INITIAL: '',
  CANCELLING: 'CANCELLING',
  CANCELLED: 'CANCELLED',
  EJECTING_CARD: 'EJECTING_CARD',
  CASH_DISPENSING: 'CASH_DISPENSING',
  CASH_DISPENSED: 'CASH_DISPENSED',
  CARD_CAPTURED: 'CARD_CAPTURED',
  CASH_RETRACTED: 'CASH_RETRACTED',
  DONE: 'DONE',
};

export const ApiContext = React.createContext({
  isSensorsOpen: false,
  setIsSensorsOpen: () => { },
  isInputAllowed: false,
  setIsInputAllowed: () => { },
  pinPadInput: '',
  setPinPadInput: () => { },
  isPageHasInput: true,
  setIsPageHasInput: () => { },
  shouldSubmit: false,
  setShouldSubmit: () => { },
  shouldCancel: false,
  setShouldCancel: () => { },
  cancelStatus: '',
  setCancelStatus: () => { },
  machineError: [],
  setMachineError: () => { },
  issueDispensingMoney: false,
  redirectToOfflinePage: false,
  setReceiptPaperStatus: () => { },
  setReceiptPrinterStatus: () => { },
  forexRateDisplay: "",
  setForexRateDisplay: () => { },
  terminalOperationStatus: "",
  setTerminalOperationStatus: () => { },
});

export const useApiContext = () => {
  return useContext(ApiContext);
}; // to be called in components

export const useProvideApi = () => {
  const [terminalOperationStatus, setTerminalOperationStatus] = useState(OPERATION_STATUSES.OK);
  const [isSensorsOpen, setIsSensorsOpen] = useState(false); // sensors still open
  /**
   * Input is allowed if /api/begin-input is called
   */
  const [isInputAllowed, setIsInputAllowed] = useState(false);
  const [pinPadInput, setPinPadInput] = useState('');
  // this value is automatically set by use input hooks, and used in session timeout hooks
  const [isPageHasInput, setIsPageHasInput] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(CANCEL_STATUSES.INITIAL);
  const [receiptPaperStatus, setReceiptPaperStatus] = useState('OK');
  const [receiptPrinterStatus, setReceiptPrinterStatus] = useState('OK');
  const [machineError, setMachineErrorRaw] = useState([]); // can have multiple machine errors
  /**
   * TODO: move to separate context if there are enough or similar to forex display
   */
  const [forexRateDisplay, setForexRateDisplay] = useState('');

  /**
   * ATM key bind - if enter or cancel key was pressed on the ATM
   */
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [shouldCancel, setShouldCancel] = useState(false);

  const setMachineError = useCallback(
    (errorCode) => {
      if (isArray(errorCode)) {
        setMachineErrorRaw([...machineError, ...errorCode]);
      } else {
        setMachineErrorRaw([...machineError, errorCode]);
      }
    },
    [machineError, setMachineErrorRaw],
  );

  const issueDispensingMoney = includes(machineError, FATAL_ERRORS[1006]);
  const issueDispensingMoneyAndReversalInconclusive = includes(machineError, FATAL_ERRORS[1007]);

  const receiptPaperOK = useMemo(() => receiptPaperStatus === RECEIPT_PAPER_STATUSES.OK, [
    receiptPaperStatus,
  ]);
  const receiptPrinterOK = useMemo(() => receiptPrinterStatus === RECEIPT_PRINTER_STATUSES.OK, [
    receiptPrinterStatus,
  ]);

  return {
    terminalOperationStatus,
    setTerminalOperationStatus,
    isSensorsOpen,
    setIsSensorsOpen,
    isInputAllowed,
    setIsInputAllowed,
    pinPadInput,
    setPinPadInput,
    isPageHasInput,
    setIsPageHasInput,
    shouldSubmit,
    setShouldSubmit,
    shouldCancel,
    setShouldCancel,
    cancelStatus,
    setCancelStatus,
    machineError,
    setMachineError,
    setReceiptPaperStatus,
    setReceiptPrinterStatus,
    issueDispensingMoney,
    redirectToOfflinePage: issueDispensingMoney || issueDispensingMoneyAndReversalInconclusive,
    canDispenseReceipt: receiptPaperOK && receiptPrinterOK,
    forexRateDisplay,
    setForexRateDisplay
  };
};

export const ProvideApi = ({ children }) => {
  const api = useProvideApi();
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
